import React, {useEffect, useState, useCallback} from 'react'
import ReactPaginate from 'react-paginate'
import {MultiSelect} from 'react-multi-select-component'
import {ApiPost, ApiGet} from '../../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast} from '../../../../../apiCommon/helpers/Toast'
import noData from '../../../../../img/NoData1.svg'
import InvoiceImg from '../../../../../img/downDoubleArrow.png'
import totalInvoiceImg from '../../../../../img/totalInvoice.png'
import upcomingExpenseImg from '../../../../../img/upcoming-expense.png'
import overdueExpenseImg from '../../../../../img/overdue-expense.png'
import paidExpenseImg from '../../../../../img/paid-expense.png'
import vatImg from '../../../../../img/vatIcon.png'
import {capitalizeFirstLetter, formatAmount} from '../../../../../Utilities/utils'
import {invertOutstandingAmount} from '../../../../../Utilities/utils'
import {CustomValueRenderer} from '../../../../../Utilities/utils'

interface PaymentItem {
  itemName: string
  parentListName: string
  totalAmount: number
  totalOverdue: number
  totalCollected: number
  totalOutstanding: number
  totalUpcoming: number
  totalRefunded: number
}

interface IncomesCardCount {
  totalItems: number
  totalAmount: number
  totalPaidAmount: number
  totalUpcomingAmount: number
  totalOverdueAmount: number
}

const ItemsTab: React.FC = () => {
  const [payments, setPayments] = useState<PaymentItem[]>([])
  const [incomesCardCount, setIncomesCardCount] = useState<IncomesCardCount | null>(null)
  const [pageLimit, setPageLimit] = useState(1)
  const [itemsPerPage] = useState(25)
  const [incomeItemsList, setIncomeItemsList] = useState<{label: string; value: string}[]>([])
  const [incomeParentItemsList, setIncomeParentItemsList] = useState<
    {label: string; value: string}[]
  >([])
  const [selectedIncomeItems, setSelectedIncomeItems] = useState<{label: string; value: string}[]>(
    []
  )
  const [selectedParentIncomeItems, setSelectedParentIncomeItems] = useState<
    {label: string; value: string}[]
  >([])
  const [currentPage, setCurrentPage] = useState(1)

  const fetchData = useCallback(
    async (page: number = 1) => {
      const selectedItemIds = selectedIncomeItems.map((item) => item.value)
      const selectedParentItemIds = selectedParentIncomeItems.map((item) => item.value)
      const body = {
        page,
        limit: itemsPerPage,
        ids: selectedItemIds,
        paymentMainItemListIds: selectedParentItemIds,
      }

      try {
        const [tableData, countData] = await Promise.all([
          ApiPost('corporate/payment_sub_item_list_global', body),
          ApiPost('corporate/payment_sub_item_list_global/get_count', {
            ids: selectedItemIds,
            paymentMainItemListIds: selectedParentItemIds,
          }),
        ])

        setPayments(tableData?.data?.data?.payment_sub_item_list_data)
        setPageLimit(tableData?.data?.data?.state?.page_limit)
        setIncomesCardCount(countData?.data?.data)
      } catch (err: any) {
        console.error('Error fetching data:', err)
        ErrorToast(err?.message || 'An error occurred while fetching data')
      }
    },
    [selectedIncomeItems, selectedParentIncomeItems, itemsPerPage]
  )

  useEffect(() => {
    fetchData(currentPage)
  }, [fetchData, currentPage])

  useEffect(() => {
    const getItemsForDropdown = async () => {
      try {
        const [subItemRes, parentItemRes] = await Promise.all([
          ApiGet('corporate/payment_sub_item_list?activityStatus=1&types=[0]'),
          ApiPost('corporate/payment_main_item_list/get', {page: 1, limit: 100}),
        ])

        const subItemResValues = subItemRes?.data?.data?.map((val: any) => ({
          label: val.name,
          value: val._id,
        }))
        setIncomeItemsList(subItemResValues)

        const parentItemResValues = parentItemRes?.data?.data?.payment_main_item_list_data?.map(
          (val: any) => ({
            label: val.name,
            value: val._id,
          })
        )
        setIncomeParentItemsList(parentItemResValues)
      } catch (err: any) {
        ErrorToast(err?.message || 'An error occurred while fetching dropdown items')
      }
    }

    getItemsForDropdown()
  }, [])

  const handlePageClick = (event: {selected: number}) => {
    const newPage = event.selected + 1
    setCurrentPage(newPage)
  }

  const handleIncomeItemsChange = (selected: {label: string; value: string}[]) => {
    setSelectedIncomeItems(selected)
    setCurrentPage(1)
  }

  const handleParentIncomeItemsChange = (selected: {label: string; value: string}[]) => {
    setSelectedParentIncomeItems(selected)
    setCurrentPage(1)
  }

  const getPositive = (number: number) => Math.abs(number)

  const formatCurrency = (amount: number) =>
    amount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})

  const renderStatsCard = (
    title: string,
    imgSrc: string,
    value: number | string,
    isNumber: boolean = false
  ) => (
    <div className='customCard py-4 px-7 shadow' style={{width: '13.5rem', height: '100px'}}>
      <h4 className='text-dark-500 mb-3'>{title}</h4>
      <div className='d-flex justify-content-start align-items-center'>
        <img src={imgSrc} height={40} width={40} className='me-3' alt={title} />
        {!isNumber && <h3 className='fw-bold ms-auto mt-5'>{'AED'}</h3>}
        {isNumber && (
          <h1 className='fw-bold ms-auto mt-3'>
            {incomesCardCount ? Number(incomesCardCount?.totalItems) : 0}
          </h1>
        )}
      </div>
      <div className='d-flex justify-content-start align-items-center'>
        {!isNumber && (
          <h3 className='fnt-500 ms-auto mt-1'>
            {value !== 0 ? `${formatCurrency(Number(value))}` : '0'}
          </h3>
        )}
      </div>
    </div>
  )

  return (
    <div className='pt-0 mt-0 no-print'>
      <div className='d-flex flex-lg-wrap flex-xl-nowrap mt-5' style={{gap: '20px'}}>
        {renderStatsCard('Items', InvoiceImg, incomesCardCount?.totalItems || 0, true)}
        {renderStatsCard('Total', totalInvoiceImg, incomesCardCount?.totalAmount || 0)}
        {renderStatsCard('Paid', paidExpenseImg, incomesCardCount?.totalPaidAmount || 0)}
        {renderStatsCard(
          'Upcoming',
          upcomingExpenseImg,
          incomesCardCount?.totalUpcomingAmount || 0
        )}
        {renderStatsCard('Overdue', overdueExpenseImg, incomesCardCount?.totalOverdueAmount || 0)}
        {renderStatsCard(
          'VAT Returns',
          vatImg,
          (incomesCardCount?.totalAmount || 0) - (incomesCardCount?.totalPaidAmount || 0)
        )}
      </div>

      <div className='d-flex align-items-center py-4 row mx-0 mt-4'>
        <div className='col-md-1 mb-2'>
          <label className='head-text' style={{fontWeight: '600'}}>
            Search
          </label>
        </div>
        <div className='col-md-5 mb-2 py-2'>
          <div className='row'>
            <div className='col-md-6 ps-1'>
              <div className='test property me-4 '>
                <div
                  className={`multi-select-container jobs-property ${
                    selectedIncomeItems.length === 0 ? 'no-value payment-item' : 'has-value'
                  }`}
                >
                  <MultiSelect
                    options={incomeItemsList}
                    value={selectedIncomeItems}
                    onChange={setSelectedIncomeItems}
                    labelledBy='Income Items'
                    overrideStrings={{
                      selectSomeItems: 'Item',
                      allItemsAreSelected: 'All Items',
                    }}
                    valueRenderer={CustomValueRenderer}
                  />
                </div>
              </div>
            </div>
            <div className='col-md-6 ps-1'>
              <div className='test property me-4 '>
                <div
                  className={`multi-select-container jobs-property ${
                    selectedParentIncomeItems.length === 0
                      ? 'no-value payment-item-list'
                      : 'has-value'
                  }`}
                >
                  <MultiSelect
                    options={incomeParentItemsList}
                    value={selectedParentIncomeItems}
                    onChange={setSelectedParentIncomeItems}
                    labelledBy='Parent Income Items'
                    overrideStrings={{
                      allItemsAreSelected: 'All Items',
                      selectSomeItems: 'Item List',
                    }}
                    valueRenderer={CustomValueRenderer}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='card card-flush gap-5 p-5 pt-1 pe-2'>
        {payments.length > 0 ? (
          <div className='card-body pt-0 table-responsive mt-1 pb-4 pe-5 ps-0' id='global-table'>
            <table
              className='table align-start table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-75px'>ID</th>
                  <th className='min-w-150px'>Items</th>
                  <th className='min-w-125px'>List</th>
                  <th className='min-w-100px'>Total Value</th>
                  <th className='min-w-125px'>Overdue</th>
                  <th className='min-w-100px'>Collected</th>
                  <th className='min-w-150px'>Outstanding</th>
                  <th className='min-w-150px'>Total Upcoming</th>
                  <th className='min-w-150px'>Refunded</th>
                </tr>
              </thead>
              <tbody className='fw-semibold head-text'>
                {payments.map((pm: any, index: number) => (
                  <tr
                    key={index}
                    className=''
                    style={{borderBottomStyle: 'dashed', height: '30px'}}
                  >
                    <td className='max-w-150px'>{pm?.id || '-'}</td>
                    <td className='max-w-150px'> {capitalizeFirstLetter(pm.itemName)}</td>
                    <td>{capitalizeFirstLetter(pm.parentListName)}</td>
                    <td>AED {formatAmount(pm.totalAmount) || '-'}</td>
                    <td style={{color: '#d54645'}}>AED {formatAmount(pm.totalOverdue) || '-'}</td>
                    <td style={{color: '#0d9e91'}}>AED {formatAmount(pm.totalCollected) || '-'}</td>
                    <td>AED {formatAmount(invertOutstandingAmount(pm?.totalOutstanding)) || '-'}</td>
                    <td style={{color: '#f38513'}}>AED {formatAmount(pm.totalUpcoming) || '-'}</td>
                    <td>
                      AED {pm.totalRefunded ? formatAmount(getPositive(pm.totalRefunded)) : '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className='card-body pt-0 table-responsive mt-1 pb-4 pe-5'>
            <table
              className='table align-start table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-75px'>ID</th>
                  <th className='min-w-150px'>Items</th>
                  <th className='min-w-125px'>List</th>
                  <th className='min-w-100px'>Total Value</th>
                  <th className='min-w-125px '>Overdue</th>
                  <th className='min-w-100px'>Collected</th>
                  <th className='min-w-150px'>Outstanding</th>
                  <th className='min-w-150px'>Total Upcoming</th>
                  <th className='min-w-150px'>Refunded</th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                <tr>
                  <td colSpan={10} className='text-center'>
                    <img src={noData} alt='No Data' width={350} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {/* Pagination */}
        <div className='row mb-5'>
          <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
          <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
            <div
              className='dataTables_paginate paging_simple_numbers'
              id='kt_ecommerce_sales_table_paginate'
            >
              <ReactPaginate
                breakLabel='...'
                nextLabel='>'
                pageClassName='paginate_button page-item'
                pageLinkClassName='page-link'
                containerClassName='pagination'
                activeClassName='active'
                previousClassName='paginate_button page-item previous'
                nextClassName='paginate_button page-item next'
                previousLinkClassName='page-link'
                nextLinkClassName='page-link'
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageLimit}
                previousLabel='<'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ItemsTab
