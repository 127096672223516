import React, {useState, useCallback, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import ownerFilledIconBlue from '../../../../img/owners-profile-blue.png'
import redCross from '../../../../img/remove.png'
import saveIcon from '../../../../img/save-dark-green.png'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import {Controller, useForm} from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import Select from 'react-select'
import moment from 'moment'
import {
  ApiGet,
  ApiPut,
  ApiGetNoAuth,
} from '../../../../apiCommon/helpers/API/ApiData'
import {Fancybox as fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import {DatePicker} from 'antd'
import {Moment} from 'moment'
import InputField from './InputField'
import {MultiSelect} from 'react-multi-select-component'

interface ButtonProps {
  show: any
  handleClose?: any
  updateLists?: any
  addedLists?: any
}

const EditOwnerModel = ({show, handleClose, updateLists, addedLists}: ButtonProps) => {
  let isHaveNationalityData = false

  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
      fontSize: '13px !important',
      fontWeight: '400 !important',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#fff',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#fff',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [ownerData, setOwnerData] = React.useState<any>({
    DOB: null,
    nationality: [],
    gender: [],
  })

  const [country, setCountry] = useState<any>([])
  const [genderOptions, setGenderOptions] = useState<any>([
    {value: 0, label: 'Female'},
    {value: 1, label: 'Male'},
    // {value: '2', label: 'Other'},
  ])

  //
  const getCountry = async () => {
    await ApiGetNoAuth('country')
      .then((res) => {
        setCountry(
          res?.data?.data.map((countries: any) => ({
            value: countries?.country,
            label: countries?.country,
          }))
        )
      })
      .catch((e: any) => {
        console.log('e', e)
      })
  }

  /************************* Documents **********************/
  const [isCTADisabled, setIsCTADisabled] = useState<any>(true)
  const [customErrors, setCustomErrors] = useState<any>({});
  const maxLengths = {
    firstName: 50,
    lastName: 50,
    idNo:50,
    passportNo:50
  };

  const validateFieldLengths = (data:any) => {
    const newErrors : any = {};
    let hasErrors = false;

    for (const [field, maxLength] of Object.entries(maxLengths)) {
      if (data[field] && data[field].length > maxLength) {
        newErrors[field] = true;
        hasErrors = true;
      }
    }

    setCustomErrors(newErrors);
    return hasErrors;
  }
  // update owner
  const updateOwner = async (data: any, e: any) => {
    e.preventDefault()
    const lengthError = validateFieldLengths(data);
    if (lengthError) {
      return;
    }
    const body: any = {
      _id: ownerData?._id,
      firstName: ownerData?.firstName,
      lastName: ownerData?.lastName,
      phoneNumber: ownerData?.phoneNumber ?  ownerData?.phoneNumber : null,
      countryCode: ownerData?.countryCode ? ownerData?.countryCode : null ,
      email: ownerData?.email,
      nationality: ownerData?.nationality?.length > 0 ? ownerData?.nationality?.[0]?.value : null,
      passportNo: ownerData?.passportNo,
      otherNo: ownerData?.otherNo,
      idNo: ownerData?.idNo,
      gender: ownerData?.gender?.length > 0 ? Number(ownerData?.gender?.[0]?.value) : null,
    }
    if (ownerData?.DOB !== null) {
      body.DOB = ownerData?.DOB
    }else{
      body.DOB = ''
    }

    await ApiPut(`corporate/owner`, body)
      .then((response) => {
        SuccessToast('Owner profile updated successfully!')
        // setIsEditTenant(false)
        handleClose(1)
      })
      .catch((error) => {
        console.log(error)
        ErrorToast(error?.message)
      })
  }

  //
  const getOwnerData = async () => {
    await ApiGet(`corporate/owner/${window.location.pathname.split('/')[2]}`)
      .then((res) => {
        const ownData = res?.data?.data

        setValue('email', res?.data?.data?.email)
        setValue(
          'phoneNumber',
          res?.data?.data?.phoneNumber
            ? `${parseInt(res?.data?.data?.countryCode)}${res?.data?.data?.phoneNumber}`
            : null
        )

        setOwnerData({
          ...ownData,
          nationality: ownData?.nationality
            ? [
                {
                  label: ownData.nationality,
                  value: ownData.nationality,
                },
              ]
            : [],
          gender:
            ownData?.gender !== undefined && ownData?.gender != null
              ? [
                  {
                    label:
                      ownData.gender === 0
                        ? 'Female'
                        : ownData.gender === 1
                        ? 'Male'
                        : ownData.gender === 2
                        ? 'Other'
                        : '-',
                    value: ownData.gender,
                  },
                ]
              : [],
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  //
  function capitalizeFirstLetter(word: any) {
    if (!word || typeof word !== 'string') {
      return word
    }
    const firstLetter = word.charAt(0)
    const firstLetterCap = firstLetter.toUpperCase()
    const remainingLetters = word.slice(1)
    const capitalizedWord = firstLetterCap + remainingLetters
    return capitalizedWord
  }

  // 
  React.useEffect(() => {
    if (ownerData?.nationality) {
      isHaveNationalityData = true
    }
  }, [ownerData?.nationality])

  useEffect(() => {
    getCountry()
    getOwnerData()
  }, [])

  useEffect(() => {
    if (
      ownerData?.firstName !== undefined &&
      ownerData?.firstName?.trim() !== '' &&
      ownerData?.lastName !== undefined &&
      ownerData?.lastName?.trim() !== '' &&
      ownerData?.email !== undefined &&
      ownerData?.email?.trim() !== ''
    ) {
      setIsCTADisabled(false)
    } else {
      setIsCTADisabled(true)
    }
  }, [ownerData?.firstName, ownerData?.lastName, ownerData?.email])

  useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })
  }, [])

  //
  function disabledDate(current: Moment) {
    return current && current > moment().endOf('day')
  }

  //
  useEffect(() => {
    if (ownerData?.lastName) {
      setValue('lastName', ownerData.lastName)
    }
    if (ownerData?.firstName) {
      setValue('firstName', ownerData.firstName)
    }
  }, [ownerData, setValue])

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose(0)
        }}
        backdrop={true}
      >
        <div
          className='modal-body p-0'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 15px 0px #516176',
            minHeight: '350px',
            maxHeight: '550px',
            overflow: 'auto',
            position: 'relative',
            // paddingBottom:'100px',
          }}
        >
          <div
            className='row px-7 py-5 m-0'
            style={{position: 'sticky', top: 0, background: '#fff', zIndex: 2}}
          >
            <div className='col-md-12 px-0'>
              <div style={{position: 'relative'}}>
                <div style={{position: 'absolute', top: 0, right: 0}}>
                  <label
                    className='head-text fs-5 cursor-pointer blue-dark-text'
                    style={{fontWeight: '700'}}
                    onClick={() => {
                      handleClose(0)
                    }}
                  >
                    X
                  </label>
                </div>
                <h2 className='head-text mb-0 text-center blue-dark-text'>
                  <b>Edit Owner Profile</b>
                </h2>
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit((data: any, e: any) => updateOwner(ownerData, e))}>
            <div className='row mx-0 px-0 mt-7'>
              <div className='col-12 px-0'>
                <img
                  src={ownerFilledIconBlue}
                  height={60}
                  width={60}
                  className='m-0 p-0 d-block mx-auto'
                />
                <h3 className='head-text mb-0 text-center mt-5 mb-5'>
                  <b>Details</b>
                </h3>
              </div>

              <div className='col-12 px-7' style={{marginBottom: '80px'}}>
                <div className='row d-flex justify-content-center' style={{columnGap: '100px'}}>
                  <div style={{width: '40%'}}>
                  {/* FIRST NAME */}
                    {/* <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='required labl-gry'> First Name </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        <input
                          {...register('firstName', {required: true})}
                          type='text'
                          className='form-control form-control-solid mytest'
                          style={{backgroundColor: '#fff'}}
                          autoComplete='off'
                          placeholder='Enter first name'
                          name='firstName'
                          value={ownerData?.firstName}
                          onChange={(e) => {
                            const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                            setOwnerData({
                              ...ownerData,
                              [e.target.name]: captilizeWord,
                            })
                          }}
                        />

                        {errors.firstName && (
                          <p
                            style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                            className='m-0 mt-1'
                          >
                            This field is required
                          </p>
                        )}
                      </h4>
                    </div> */}
                    <InputField
                      register={register}
                      errors={errors}
                      name="firstName"
                      label="First Name"
                      placeholder="Enter first name"
                      value={ownerData?.firstName}
                      onChange={(e:any) => setOwnerData({...ownerData, [e.target.name]: e.target.value})}
                      maxLength={maxLengths.firstName}
                      customError={customErrors?.firstName}
                    />
                    {/* LAST NAME */}
                    {/* <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='required labl-gry'> Last Name </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        <input
                          type='text'
                          {...register('lastName', {required: true})}
                          className='form-control form-control-solid mytest'
                          placeholder='Enter last name'
                          autoComplete='off'
                          name='lastName'
                          style={{backgroundColor: '#fff'}}
                          value={ownerData?.lastName}
                          onChange={(e) => {
                            const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                            setOwnerData({
                              ...ownerData,
                              [e.target.name]: captilizeWord,
                            })
                          }}
                        />

                        {errors.lastName && (
                          <p
                            style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                            className='m-0 mt-1'
                          >
                            This field is required
                          </p>
                        )}
                      </h4>
                    </div> */}
                    <InputField
                      register={register}
                      errors={errors}
                      name="lastName"
                      label="Last Name"
                      placeholder="Enter last name"
                      value={ownerData?.lastName}
                      onChange={(e:any) => setOwnerData({...ownerData, [e.target.name]: e.target.value})}
                      maxLength={maxLengths.lastName}
                      customError={customErrors?.lastName}
                    />
                    {/* EMAIL */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='required labl-gry'> Email </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2 test'>
                        <Controller
                          name='email'
                          control={control}
                          rules={{
                            required: 'Email is required',
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: 'Invalid email address',
                            },
                          }}
                          render={({field}) => (
                            <input
                              //   {...register('email', {required: true})}
                              type='text'
                              className='form-control form-control-solid mytest'
                              placeholder='Enter email address'
                              autoComplete='off'
                              style={{backgroundColor: '#fff'}}
                              name='email'
                              value={ownerData?.email}
                              onChange={(e) => {
                                setValue('email', e.target.value)
                                setOwnerData({
                                  ...ownerData,
                                  [e.target.name]: e.target.value,
                                })
                              }}
                              disabled
                            />
                          )}
                        />

                        {errors.email && (
                          <p
                            style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                            className='m-0 mt-1'
                          >
                            Please enter a valid Email Address
                            {/* {errors.email.message} */}
                          </p>
                        )}
                      </h4>
                    </div>
                    {/* MOBILE NO */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='labl-gry'> Mobile No. </label>
                      </h4>

                      <h4
                        style={{width: '100%', borderRadius: '0.475rem'}}
                        className='my-2  test2 owner'
                      >
                        <Controller
                          name='phoneNumber'
                          control={control}
                          render={({field}) => (
                            <PhoneInput
                              //   {...register('phoneNumber', {required: true})}
                              containerClass={
                                ownerData?.countryCode && ownerData?.phoneNumber ? 'isFilled' : ''
                              }
                              placeholder='Enter phone number'
                              value={
                                ownerData?.phoneNumber
                                  ? `${ownerData?.countryCode} ${ownerData?.phoneNumber}`
                                  : null
                              }
                              onChange={(phone: any, e: any) => {
                                let CountryCode: any = e?.dialCode
                                let PhoneNumber: any = phone.split(CountryCode)[1]
                                setOwnerData({
                                  ...ownerData,
                                  ['countryCode']: parseInt(CountryCode),
                                  ['phoneNumber']: PhoneNumber,
                                })

                                setValue('phoneNumber', `${parseInt(CountryCode)}${PhoneNumber}`)
                              }}
                            />
                          )}
                        />

                        {errors.phoneNumber && (
                          <p
                            style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                            className='m-0 mt-1'
                          >
                            This field is required
                            {/* {errors.email.message} */}
                          </p>
                        )}
                      </h4>
                    </div>
                  </div>
                  <div style={{width: '40%'}}>
                    {/* DOB */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '94%'}}>
                        <label className='labl-gry'> DOB </label>
                      </h4>
                      <h4 className='my-2 add-tenant-date tst owner'>
                        {ownerData?.DOB != null ? (
                          <DatePicker
                            className={`w-100 ${ownerData?.DOB ? 'isFilled' : ''}`}
                            value={moment(ownerData.DOB, 'YYYY-MM-DD')}
                            format={'DD.MM.YYYY'}
                            disabledDate={disabledDate}
                            onChange={(date: any) =>
                              setOwnerData({
                                ...ownerData,
                                ['DOB']: date != null ? date.format('YYYY-MM-DD') : null,
                              })
                            }
                            placeholder='Select DOB'
                          />
                        ) : (
                          <DatePicker
                            format={'DD.MM.YYYY'}
                            disabledDate={disabledDate}
                            onChange={(date: any) =>
                              setOwnerData({
                                ...ownerData,
                                ['DOB']: date != null ? date.format('YYYY-MM-DD') : null,
                              })
                            }
                            placeholder='Select DOB'
                            className='w-100'
                          />
                        )}

                        {errors.DOB && (
                          <p
                            style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                            className='m-0 mt-1'
                          >
                            This field is required
                          </p>
                        )}
                      </h4>
                    </div>
                    {/* NATIONALITY */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start xyz tst'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='labl-gry'> Nationality </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        {/* <Select
                          // defaultValue={ownerData?.gender == 0 ? genderOptions[0] : ownerData?.gender == 2 ? genderOptions[2] : genderOptions[1]}
                          // {...register('gender', {required: true})}
                          value={
                            country?.filter(
                              (country: any) => country?.label === ownerData?.nationality
                            )?.[0]
                          }
                          styles={{
                            ...customStyles,
                            control: (defaultStyles: any, state: any) => ({
                              ...defaultStyles,
                              backgroundColor: '#fff',
                              boxShadow: 'none',
                              color: 'white',
                              border: ownerData?.nationality
                                ? '0.1rem solid #35475e'
                                : '0.1rem solid #bec3cb',
                              borderRadius: '2px',
                              padding: '0rem',
                              height: '35px',
                              ':hover': {
                                borderColor: '#35475e',
                                backgroundColor: '#fff',
                              },
                              ':focus': {
                                borderColor: '#35475e !important',
                                backgroundColor: '#fff',
                              },
                              '::placeholder': {
                                fontStyle: 'Italic',
                              },
                            }),
                          }}
                          isSearchable={false}
                          options={country}
                          placeholder='Select nationality'
                          name='nationality'
                          onChange={(e: any) =>
                            setOwnerData({
                              ...ownerData,
                              ['nationality']: e?.value,
                            })
                          }
                        /> */}

                        <div className='test jobs owner'>
                          <div className='property-test d-none-multi-checbox'>
                            <div
                              className={`multi-select-container jobs-property ms-0 nationality ${
                                ownerData?.nationality?.length === 0 ? 'no-value' : 'has-value'
                              }`}
                            >
                              <MultiSelect
                                options={country}
                                value={ownerData?.nationality}
                                onChange={(e: any) => {
                                  let newVal: any = e
                                  if (e?.length > 1) newVal = e.slice(1)
                                  {
                                    setOwnerData({
                                      ...ownerData,
                                      ['nationality']: newVal,
                                    })
                                  }
                                }}
                                labelledBy=''
                                overrideStrings={{
                                  allItemsAreSelected: '',
                                }}
                                hasSelectAll={false}
                                closeOnChangedValue={true}
                              />
                            </div>
                          </div>
                        </div>
                      </h4>
                    </div>

                    {/* GENDER */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start xyz tst'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='labl-gry'> Gender </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        {/* <Select
                          defaultValue={
                            ownerData?.gender == 0
                              ? genderOptions[0]
                              : ownerData?.gender == 1
                              ? genderOptions[1]
                              : {}
                          }
                          value={
                            ownerData?.gender == 0
                              ? genderOptions[0]
                              : ownerData?.gender == 1
                              ? genderOptions[1]
                              : {}
                          }
                          // {...register('gender', {required: true})}
                          styles={{
                            ...customStyles,
                            control: (defaultStyles: any, state: any) => ({
                              ...defaultStyles,
                              backgroundColor: '#fff',
                              boxShadow: 'none',
                              color: 'white',
                              border: ownerData?.gender
                                ? '0.1rem solid #35475e'
                                : '0.1rem solid #bec3cb',
                              borderRadius: '2px',
                              padding: '0rem',
                              height: '35px',
                              ':hover': {
                                borderColor: '#35475e',
                                backgroundColor: '#fff',
                              },
                              ':focus': {
                                borderColor: '#35475e !important',
                                backgroundColor: '#fff',
                              },
                              '::placeholder': {
                                fontStyle: 'Italic',
                              },
                            }),
                          }}
                          isSearchable={false}
                          options={genderOptions}
                          placeholder='Select gender'
                          name='gender'
                          onChange={(e: any) =>
                            setOwnerData({
                              ...ownerData,
                              ['gender']: e?.value,
                            })
                          }
                        /> */}
                        <div className='test jobs owner'>
                          <div className='property-test d-none-multi-checbox'>
                            <div
                              className={`multi-select-container jobs-property ms-0 gender ${
                                ownerData?.gender?.length === 0 ? 'no-value' : 'has-value'
                              }`}
                            >
                              <MultiSelect
                                options={genderOptions}
                                value={ownerData?.gender}
                                onChange={(e: any) => {
                                  let newVal: any = e
                                  if (e?.length > 1) newVal = e.slice(1)
                                  {
                                    setOwnerData({
                                      ...ownerData,
                                      ['gender']: newVal,
                                    })
                                  }
                                }}
                                labelledBy=''
                                overrideStrings={{
                                  allItemsAreSelected: '',
                                }}
                                hasSelectAll={false}
                                disableSearch
                                closeOnChangedValue={true}
                              />
                            </div>
                          </div>
                        </div>
                      </h4>
                    </div>

                    {/* ID NO. */}
                    {/* <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='labl-gry'> ID No. </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        <input
                          type='text'
                          className='form-control form-control-solid mytest '
                          placeholder='Enter ID number'
                          autoComplete='off'
                          name='idNo'
                          style={{backgroundColor: '#fff'}}
                          value={ownerData?.idNo}
                          onChange={(e) => {
                            const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                            setOwnerData({
                              ...ownerData,
                              [e.target.name]: captilizeWord,
                            })
                          }}
                        />
                      </h4>
                    </div> */}
                     <InputField
                      isRequired={false}
                      register={register}
                      errors={errors}
                      name="idNo"
                      label="ID No."
                      placeholder="Enter ID number"
                      value={ownerData?.idNo}
                      onChange={(e:any) => setOwnerData({...ownerData, [e.target.name]: e.target.value})}
                      maxLength={maxLengths?.idNo}
                      customError={customErrors?.idNo}
                    />

                    {/* Passport No. */}
                    {/* <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='labl-gry'> Passport No. </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        <input
                          type='text'
                          className='form-control form-control-solid mytest'
                          placeholder='Enter passport number'
                          autoComplete='off'
                          name='passportNo'
                          style={{backgroundColor: '#fff'}}
                          value={ownerData?.passportNo}
                          onChange={(e) => {
                            const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                            setOwnerData({
                              ...ownerData,
                              [e.target.name]: captilizeWord,
                            })
                          }}
                        />
                      </h4>
                    </div> */}
                    <InputField
                      isRequired={false}
                      register={register}
                      errors={errors}
                      name="passportNo"
                      label="Passport No."
                      placeholder="Enter passport number"
                      value={ownerData?.passportNo}
                      onChange={(e:any) => setOwnerData({...ownerData, [e.target.name]: e.target.value})}
                      maxLength={maxLengths?.passportNo}
                      customError={customErrors?.passportNo}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className='row py-5 m-0 shadow-sm'
              style={{position: 'sticky', bottom: 0, background: '#fff', zIndex: 2}}
            >
              <div className='col-12 d-flex justify-content-between align-items-center'>
                <button
                  type='button'
                  className='btn btn-sm fw-bold red-hollow-btn ms-7'
                  onClick={() => {
                    handleClose(0)
                  }}
                >
                  <img src={redCross} height={17} width={17} className='me-3' /> Cancel
                </button>
                <button
                  type='submit'
                  className='btn btn-sm fw-bold px-2 green-hollow-btn justify-content-center ms-7 me-7'
                >
                  <img src={saveIcon} height={17} width={17} className='me-3' /> Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}

export default EditOwnerModel
