import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import backArrow from '../../../../../../../../../img/back-arrow.png'

const AddBookingFeeConfiguration = () => {
  const navigate = useNavigate()
  const {type, id} = useParams()
 
  return (
    <>
      <div className='d-flex align-items-end gap-2 mb-3 gap-lg-3'>
        <div className='d-flex align-items-start gap-3'>
          <span
            className='rounded-circle'
            style={{cursor: 'pointer'}}
            onClick={() => navigate(`/settings/residential-leasing/annual-leases/${type}/${id}`)}
          >
            <img src={backArrow} height={14} width={14} alt='Back' />
          </span>
          <div>
            <h2 className='page-heading m-0 head-text mb-1'>Charge Configuration Settings</h2>
            <p className='labl-gry fs-4 mb-0 light-dark-theme-color'>
              Configure your charge settings
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddBookingFeeConfiguration
