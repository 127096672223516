import React from 'react'
import {Modal, Button, Form} from 'react-bootstrap'
import switchOffIcon from '../../../../img/switch-off.png'
import switchOnIcon from '../../../../img/switch-on.png'

interface StatusModalI {
  show: boolean
  onHide: any
  onDisable: any
  modelName: string
  isChangeHappened: any
  setIsChangeHappened: any
}

const StatusModal = ({
  show,
  onHide,
  onDisable,
  modelName,
  isChangeHappened,
  setIsChangeHappened,
}: StatusModalI) => {
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-450px mx-auto'
      show={show}
      onHide={() => {
        onHide()
      }}
      backdrop={true}
    >
      <div
        className='modal-body p-0'
        style={{
          transform: 'translate(0px, -70px)',
          top: '70px',
          borderRadius: '5px',
          boxShadow: '0px 0px 15px 0px #516176',
          minHeight: '200px',
          maxHeight: '400px',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <div
          className='row px-6 py-5 m-0'
          style={{position: 'sticky', top: 0, background: '#fff', zIndex: 2}}
        >
          <div className={`col-12 px-0`}>
            <div className='d-flex justify-content-end'>
              <label
                className='head-text fs-5 cursor-pointer blue-dark-text fnt-700'
                onClick={() => {
                  onHide()
                }}
              >
                X
              </label>
            </div>
          </div>

          <div className='row d-flex flex-column align-items-center'>
            <div className='d-flex justify-content-center'>
              <h2 className='head-text mb-0 blue-dark-text'>
                <b>{`${modelName} Late Payment Fee`}</b>
              </h2>
            </div>
            <div className='d-flex justify-content-center mt-3'>
              <div className='d-flex align-items-center justify-content-center'>
                <img
                  src={modelName === 'Enable' ? switchOnIcon : switchOffIcon}
                  height={25}
                  width={25}
                />
                <p className='m-0 ms-3'>{modelName}</p>
              </div>
            </div>

            <div>
              <p
                className='my-3 text-center'
                style={{color: '#000000', width: '100%', fontSize: '13px'}}
              >
                You are about to enable this late payment fee.
              </p>
              {modelName === 'Enable' ? (
                <p
                  className='my-3 text-center'
                  style={{color: '#000000', width: '100%', fontSize: '13px'}}
                >
                  Once enabled, you will be able to select and apply it when creating and
                  editing a payment or invoice.
                </p>
              ) : (
                <p
                  className='my-3 text-center'
                  style={{color: '#000000', width: '100%', fontSize: '13px'}}
                >
                  Once disabled, it will be removed from all the payments it was applied
                  to and you will not be able to select it when creating and editing a
                  payment or invoice.
                </p>
              )}
              {modelName === 'Disable' && (
                <p
                  className='my-3 text-center fw-bold'
                  style={{color: '#000000', width: '100%', fontSize: '13px'}}
                >
                  All previous charges generated by the late payment fee will not be
                  removed.
                </p>
              )}
            </div>
            <div className='col-12 my-4'>
              {modelName === 'Enable' ? (
                <button
                  type='button'
                  className='btn btn-sm fw-bold select-btn px-2 mx-auto justify-content-center'
                  style={{width: '120px'}}
                  onClick={() => {
                    setIsChangeHappened(Date.now())
                    onHide()
                  }}
                >
                  Enable
                </button>
              ) : (
                <button
                  type='button'
                  className='btn btn-sm fw-bold px-2 mx-auto justify-content-center'
                  style={{
                    width: '120px',
                    backgroundColor: '#a6a6a6',
                    border: 'none',
                    color: 'white',
                    fontSize: '11px',
                    height: '31px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    setIsChangeHappened(Date.now())
                    onHide()
                  }}
                >
                  Disable
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default StatusModal
