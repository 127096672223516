
export type InvoicingOptions = {
    label: string
    value: boolean
  }
  
  export type InstallmentOptions = {
    label: string
    value: number
  }
  
  export type CustomFrequencyOptions = InstallmentOptions
  export type StartApplyingOptions = InstallmentOptions
  export type DueDateOptions = InstallmentOptions


export const customFrequencyOptions : Array<CustomFrequencyOptions> = [
    {
      label: 'Every Day',
      value: 0,
    },
    {
      label: 'Every Week',
      value: 1,
    },
    {
      label: 'Every Month',
      value: 2,
    },
  ]

  export const invoicingOptions: Array<InvoicingOptions> = [
    {
      label: 'Enabled',
      value: true,
    },
    {
      label: 'Disabled',
      value: false,
    },
  ]
  export const installmentOptions: Array<InstallmentOptions> = [
    {
      label: 'As Per Rent Installments',
      value: 0,
    },
    {
      label: 'Custom Split',
      value: 1,
    },
    {
      label: 'No Split',
      value: 2,
    },
  ]
 export const startApplyingOptions: Array<StartApplyingOptions> = [
    {
      label: 'Start Date Of The Lease',
      value: 0,
    },
    {
      label: 'When A % Of The Payment In A Lease Are Received',
      value: 1,
    },
    {
      label: 'Manually Set The Start Date At The Time Of Creating The Booking Or Lease',
      value: 2,
    },
  ]
  export const dueDateOptions: Array<DueDateOptions> = [
    {
      label: 'Time Charge Is Applied',
      value: 0,
    },
    {
      label: 'Days After Charge Is Applied',
      value: 1,
    },
    {
      label: 'Same Day Rent Installment Is Due',
      value: 2,
    },
  ]

  export const dueDateRentOptions: Array<DueDateOptions> = [{
    label: 'Same Day Rent Installment Is Due',
    value: 2,
  }]

  export const frequencyOptions = [
    {label: 'As Per Rent Installments', value: 0},
    {label: 'Daily', value: 1},
    {label: 'Weekly', value: 2},
    {label: 'Monthly', value: 3},
    {label: 'Custom', value: 4},
  ]

  export const applicableEndOptions= [
    {label: 'End Date Of The Lease', value: 0},
    {label: 'Until A % Of The Payments In A Lease Are Received', value: 1},
    {label: 'Manually Set The End Date At The Time Of Creating The Booking Or Lease', value: 2},
  ]

  export const chargeOptions = [
    {label: 'Total Rent Value', value: 0},
    {label: 'Single Rent Installment', value: 1}
  ]