import React, {useState, useCallback, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import tenantUserFilledIconBlue from '../../../../../img/tenant-user-filled-blue.png'
import redCross from '../../../../../img/remove.png'
import saveIcon from '../../../../../img/save-dark-green.png'
import {SuccessToast, ErrorToast} from '../../../../../apiCommon/helpers/Toast'
import {Controller, useForm} from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import moment from 'moment'
import {ApiGet, ApiPut, ApiGetNoAuth} from '../../../../../apiCommon/helpers/API/ApiData'
import {Fancybox as fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import {DatePicker} from 'antd'
import {Moment} from 'moment'
import {MultiSelect} from 'react-multi-select-component'
import { capitalizeFirstLetter } from '../../../../../Utilities/utils'

interface ButtonProps {
  show: any
  handleClose?: any
  updateLists?: any
  addedLists?: any
}

const EditTenantModel = ({show, handleClose, updateLists, addedLists}: ButtonProps) => {
  let isHaveNationalityData = false

  // const customStyles = {
  //   container: (defaultStyles: any) => ({
  //     ...defaultStyles,
  //     width: '100%',
  //   }),

  //   option: (defaultStyles: any, state: any) => ({
  //     ...defaultStyles,
  //     color: state.isSelected ? '#000' : '#000',
  //     backgroundColor: state.isSelected ? '#c2d8d9' : '',
  //     ':hover': {
  //       backgroundColor: '#e0f5f0',
  //       color: '#000',
  //     },

  //     fontWeight: '500',
  //   }),

  //   menu: (defaultStyles: any) => ({
  //     ...defaultStyles,
  //     border: '0.1rem solid #35475e ',
  //     marginTop: '0px',
  //     borderRadius: '0px',
  //     zIndex: '2',
  //   }),
  //   menuList: (defaultStyles: any) => ({
  //     ...defaultStyles,
  //     maxHeight: '200px',
  //   }),
  //   placeholder: (defaultStyles: any) => ({
  //     ...defaultStyles,
  //     fontStyle: 'Italic',
  //     zIndex: '1',
  //     fontSize: '13px !important',
  //     fontWeight: '400 !important',
  //   }),

  //   input: (defaultStyles: any, state: any) => ({
  //     ...defaultStyles,
  //     backgroundColor: '#fff',
  //     color: ' #b5b5c3',
  //     fontWeight: '500',
  //   }),

  //   control: (defaultStyles: any, state: any) => ({
  //     ...defaultStyles,
  //     backgroundColor: '#fff',
  //     boxShadow: 'none',
  //     color: 'white',
  //     border: '0.1rem solid #bec3cb',
  //     borderRadius: '2px',
  //     padding: '0rem',
  //     height: '35px',
  //     ':hover': {
  //       borderColor: '#35475e',
  //       backgroundColor: '#fff',
  //     },
  //     ':focus': {
  //       borderColor: '#35475e !important',
  //       backgroundColor: '#fff',
  //     },
  //     '::placeholder': {
  //       fontStyle: 'Italic',
  //     },
  //   }),
  //   singleValue: (defaultStyles: any) => ({
  //     ...defaultStyles,
  //     color: '#5e6278',
  //     fontWeight: '600',
  //     zIndex: '1',
  //   }),
  //   valueContainer: (defaultStyles: any) => ({
  //     ...defaultStyles,
  //     padding: '0px 8px 2px 8px',
  //   }),
  // }

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [tenantData, setTenantData] = React.useState<any>({
    DOB: null,
    nationality: [],
    gender: [],
  })
  const [country, setCountry] = useState<any>([])
  const [genderOptions, setGenderOptions] = useState<any>([
    {value: 0, label: 'Female'},
    {value: 1, label: 'Male'},
    // {value: '2', label: 'Other'},
  ])

  //
  const getCountry = async () => {
    await ApiGetNoAuth('country')
      .then((res) => {
        setCountry(
          res?.data?.data.map((countries: any) => ({
            value: countries?.country,
            label: countries?.country,
          }))
        )
      })
      .catch((e: any) => {
        console.log('e', e)
      })
  }

  const [isCTADisabled, setIsCTADisabled] = useState<any>(true)

  //
  const updateTenant = async (data: any, e: any) => {
    // console.log(tenantData)

    e.preventDefault()
    const body: any = {
      _id: tenantData?._id,
      firstName: tenantData?.firstName,
      lastName: tenantData?.lastName,
      phoneNumber: tenantData?.phoneNumber,
      countryCode: tenantData?.countryCode,
      email: tenantData?.email,
      nationality: tenantData?.nationality?.length > 0 ? tenantData?.nationality?.[0]?.value : null,
      // tenantType: tenantData?.tenantType,
      //   DOB: tenantData?.DOB  === null ? '' : tenantData?.DOB,
      passportNo: tenantData?.passportNo,
      otherNo: tenantData?.otherNo,
      idNo: tenantData?.idNo,
      // folder_list: folders,
      gender: tenantData?.gender?.length > 0 ? Number(tenantData?.gender?.[0]?.value) : null,
      //   profileImage: profileImg,
    }
    if (tenantData?.DOB !== null) {
      body.DOB = tenantData?.DOB
    }
    // console.log(body)
    await ApiPut(`corporate/tenant`, body)
      .then((response) => {
        SuccessToast('Tenant profile updated successfully!')
        // setIsEditTenant(false)
        handleClose(1)
      })
      .catch((error) => {
        console.log(error)
        ErrorToast(error?.message)
      })
  }

  //
  const getTenantData = async () => {
    await ApiGet(`corporate/tenant/${window.location.pathname.split('/')[2]}`)
      .then((res) => {
        const tenantData = res?.data?.data
        // console.log('\nres?.data?.data', res?.data?.data)
        setValue('email', res?.data?.data?.email)
        setValue(
          'phoneNumber',
          res?.data?.data?.phoneNumber
            ? `${parseInt(res?.data?.data?.countryCode)}${res?.data?.data?.phoneNumber}`
            : null
        )
        // setProfileImg(res?.data?.data?.profileImage)

        setTenantData({
          ...tenantData,
          nationality: tenantData?.nationality
            ? [
                {
                  label: tenantData.nationality,
                  value: tenantData.nationality,
                },
              ]
            : [],
          gender:
            tenantData?.gender !== undefined && tenantData?.gender != null
              ? [
                  {
                    label:
                      tenantData.gender === 0
                        ? 'Female'
                        : tenantData.gender === 1
                        ? 'Male'
                        : tenantData.gender === 2
                        ? 'Other'
                        : '-',
                    value: tenantData.gender,
                  },
                ]
              : [],
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  React.useEffect(() => {
    if (tenantData?.nationality) {
      isHaveNationalityData = true
    }
  }, [tenantData?.nationality])

  useEffect(() => {
    getCountry()
    getTenantData()
  }, [])

  useEffect(() => {
    if (
      tenantData?.firstName !== undefined &&
      tenantData?.firstName?.trim() !== '' &&
      tenantData?.lastName !== undefined &&
      tenantData?.lastName?.trim() !== '' &&
      tenantData?.email !== undefined &&
      tenantData?.email?.trim() !== ''
    ) {
      setIsCTADisabled(false)
    } else {
      setIsCTADisabled(true)
    }
  }, [tenantData?.firstName, tenantData?.lastName, tenantData?.email])

  useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })
  }, [])

  function disabledDate(current: Moment) {
    return current && current > moment().endOf('day')
  }

  useEffect(() => {
    if (tenantData?.lastName) {
      setValue('lastName', tenantData.lastName)
    }
    if (tenantData?.firstName) {
      setValue('firstName', tenantData.firstName)
    }
  }, [tenantData, setValue])

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose(0)
        }}
        backdrop={true}
      >
        <div
          className='modal-body p-0'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 15px 0px #516176',
            minHeight: '350px',
            maxHeight: '550px',
            overflow: 'auto',
            position: 'relative',
            // paddingBottom:'100px',
          }}
        >
          <div
            className='row px-7 py-5 m-0'
            style={{position: 'sticky', top: 0, background: '#fff', zIndex: 2}}
          >
            <div className='col-md-12 px-0'>
              <div style={{position: 'relative'}} >
                <div style={{position: 'absolute', top: 0, right: 0}}>
                  <label
                    className='head-text fs-5 cursor-pointer blue-dark-text'
                    style={{fontWeight: '700'}}
                    onClick={() => {
                      handleClose(0)
                    }}
                  >
                    X
                  </label>
                </div>
                <h2 className='head-text mb-0 text-center blue-dark-text'>
                  <b>Edit Tenant Profile</b>
                </h2>
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit((data: any, e: any) => updateTenant(tenantData, e))}>
            <div className='row mx-0 px-0 mt-7'>
              <div className='col-12 px-0'>
                <img
                  src={tenantUserFilledIconBlue}
                  height={60}
                  width={60}
                  className='m-0 p-0 d-block mx-auto'
                />
                <h3 className='head-text mb-0 text-center mt-5 mb-5'>
                  <b>Details</b>
                </h3>
              </div>

              <div className='col-12 px-7' style={{marginBottom: '80px'}}>
                <div className='row d-flex justify-content-center' style={{columnGap: '100px'}}>
                  {/* FIRST NAME */}
                  <div style={{width: '40%'}}>
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='required labl-gry'> First Name </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        <input
                          {...register('firstName', {required: true})}
                          type='text'
                          className='form-control form-control-solid mytest'
                          style={{backgroundColor: '#fff'}}
                          autoComplete='off'
                          placeholder='Enter first name'
                          name='firstName'
                          value={tenantData?.firstName}
                          onChange={(e) => {
                            const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                            setTenantData({
                              ...tenantData,
                              [e.target.name]: captilizeWord,
                            })
                          }}
                        />

                        {errors.firstName && (
                          <p
                            style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                            className='m-0 mt-1'
                          >
                            This field is required
                          </p>
                        )}
                      </h4>
                    </div>
                    {/* LAST NAME */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='required labl-gry'> Last Name </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        <input
                          type='text'
                          {...register('lastName', {required: true})}
                          className='form-control form-control-solid mytest'
                          placeholder='Enter last name'
                          autoComplete='off'
                          name='lastName'
                          style={{backgroundColor: '#fff'}}
                          value={tenantData?.lastName}
                          onChange={(e) => {
                            const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                            setTenantData({
                              ...tenantData,
                              [e.target.name]: captilizeWord,
                            })
                          }}
                        />

                        {errors.lastName && (
                          <p
                            style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                            className='m-0 mt-1'
                          >
                            This field is required
                          </p>
                        )}
                      </h4>
                    </div>
                    {/* EMAIL */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='required labl-gry'> Email </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2 test'>
                        <Controller
                          name='email'
                          control={control}
                          rules={{
                            required: 'Email is required',
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: 'Invalid email address',
                            },
                          }}
                          render={({field}) => (
                            <input
                              //   {...register('email', {required: true})}
                              type='text'
                              className='form-control form-control-solid mytest'
                              placeholder='Enter email address'
                              autoComplete='off'
                              style={{backgroundColor: '#fff'}}
                              name='email'
                              value={tenantData?.email}
                              onChange={(e) => {
                                setValue('email', e.target.value)
                                setTenantData({
                                  ...tenantData,
                                  [e.target.name]: e.target.value,
                                })
                              }}
                              disabled
                            />
                          )}
                        />

                        {errors.email && (
                          <p
                            style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                            className='m-0 mt-1'
                          >
                            Please enter a valid Email Address
                            {/* {errors.email.message} */}
                          </p>
                        )}
                      </h4>
                    </div>
                    {/* MOBILE NO */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='labl-gry'> Mobile No. </label>
                      </h4>

                      <h4
                        style={{width: '100%', borderRadius: '0.475rem'}}
                        className='my-2 test test2'
                      >
                        <Controller
                          name='phoneNumber'
                          control={control}
                          render={({field}) => (
                            <PhoneInput
                              //   {...register('phoneNumber', {required: true})}
                              containerClass={
                                tenantData?.countryCode && tenantData?.phoneNumber ? 'isFilled' : ''
                              }
                              placeholder='Enter phone number'
                              value={
                                tenantData?.phoneNumber
                                  ? `${tenantData?.countryCode} ${tenantData?.phoneNumber}`
                                  : null
                              }
                              onChange={(phone: any, e: any) => {
                                let CountryCode: any = e?.dialCode
                                let PhoneNumber: any = phone.split(CountryCode)[1]
                                setTenantData({
                                  ...tenantData,
                                  ['countryCode']: parseInt(CountryCode),
                                  ['phoneNumber']: PhoneNumber,
                                })

                                setValue('phoneNumber', `${parseInt(CountryCode)}${PhoneNumber}`)
                              }}
                            />
                          )}
                        />

                        {errors.phoneNumber && (
                          <p
                            style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                            className='m-0 mt-1'
                          >
                            This field is required
                            {/* {errors.email.message} */}
                          </p>
                        )}
                      </h4>
                    </div>
                  </div>
                  <div style={{width: '40%'}}>
                    {/* DOB */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '94%'}}>
                        <label className='labl-gry'> DOB </label>
                      </h4>
                      <h4 className='my-2 add-tenant-date tst owner'>
                        {tenantData?.DOB != null ? (
                          <DatePicker
                            className={`w-100 ${tenantData?.DOB ? 'isFilled' : ''}`}
                            value={moment(tenantData.DOB, 'YYYY-MM-DD')}
                            format={'DD.MM.YYYY'}
                            disabledDate={disabledDate}
                            onChange={(date: any) =>
                              setTenantData({
                                ...tenantData,
                                ['DOB']: date != null ? date.format('YYYY-MM-DD') : null,
                              })
                            }
                            placeholder='Select DOB'
                          />
                        ) : (
                          <DatePicker
                            className={`w-100`}
                            format={'DD.MM.YYYY'}
                            disabledDate={disabledDate}
                            onChange={(date: any) =>
                              setTenantData({
                                ...tenantData,
                                ['DOB']: date != null ? date.format('YYYY-MM-DD') : null,
                              })
                            }
                            placeholder='Select DOB'
                          />
                        )}

                        {/* <input
                          type='date'
                          {...register('DOB', {required: false})}
                          className='form-control form-control-solid mytest'
                          placeholder='Select DOB'
                          max={new Date().toISOString().split('T')[0]}
                          name='DOB'
                          style={{backgroundColor: '#fff'}}
                          // value={moment(
                          //   tenantData?.DOB && tenantData?.DOB.split('T')[0]
                          // ).format('YYYY-MM-DD')}
                          onChange={(e) =>
                            setTenantData({...tenantData, [e.target.name]: e.target.value})
                          }
                        /> */}

                        {errors.DOB && (
                          <p
                            style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                            className='m-0 mt-1'
                          >
                            This field is required
                          </p>
                        )}
                      </h4>
                    </div>
                    {/* NATIONALITY */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start xyz tst'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='labl-gry'> Nationality </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        {/* <Select
                          // defaultValue={tenantData?.gender == 0 ? genderOptions[0] : tenantData?.gender == 2 ? genderOptions[2] : genderOptions[1]}
                          // {...register('gender', {required: true})}
                          value={country?.filter((country:any) => country?.label === tenantData?.nationality)?.[0]}
                          styles={{...customStyles,control: (defaultStyles: any, state: any) => ({
                            ...defaultStyles,
                            backgroundColor: '#fff',
                            boxShadow: 'none',
                            color: 'white',
                            border: tenantData?.nationality  ? '0.1rem solid #35475e' : '0.1rem solid #bec3cb',
                            borderRadius: '2px',
                            padding: '0rem',
                            height: '35px',
                            ':hover': {
                              borderColor: '#35475e',
                              backgroundColor: '#fff',
                            },
                            ':focus': {
                              borderColor: '#35475e !important',
                              backgroundColor: '#fff',
                            },
                            '::placeholder': {
                              fontStyle: 'Italic',
                            },
                          })}}
                          isSearchable={false}
                          options={country}
                          placeholder='Select nationality'
                          name='nationality'
                          onChange={(e: any) =>
                            setTenantData({
                              ...tenantData,
                              ['nationality']: e?.value,
                            })
                          }
                        /> */}

                        <div className='test jobs owner'>
                          <div className='property-test d-none-multi-checbox'>
                            <div
                              className={`multi-select-container jobs-property ms-0 nationality ${
                                tenantData?.nationality?.length === 0 ? 'no-value' : 'has-value'
                              }`}
                            >
                              <MultiSelect
                                options={country}
                                value={tenantData?.nationality}
                                onChange={(e: any) => {
                                  let newVal: any = e
                                  if (e?.length > 1) newVal = e.slice(1)
                                  {
                                    setTenantData({
                                      ...tenantData,
                                      ['nationality']: newVal,
                                    })
                                  }
                                }}
                                labelledBy=''
                                overrideStrings={{
                                  allItemsAreSelected: '',
                                }}
                                hasSelectAll={false}
                                closeOnChangedValue={true}
                              />
                            </div>
                          </div>
                        </div>
                      </h4>
                    </div>
                    {/* GENDER */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start xyz tst'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='labl-gry'> Gender </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        {/* <Select
                          defaultValue={tenantData?.gender == 0 ? genderOptions[0] : tenantData?.gender == 1 ? genderOptions[1] : {}}
                          value={tenantData?.gender == 0 ? genderOptions[0] : tenantData?.gender == 1 ? genderOptions[1] : {}}
                          // {...register('gender', {required: true})}
                          styles={{...customStyles,control: (defaultStyles: any, state: any) => ({
                            ...defaultStyles,
                            backgroundColor: '#fff',
                            boxShadow: 'none',
                            color: 'white',
                            border: tenantData?.gender  ? '0.1rem solid #35475e' : '0.1rem solid #bec3cb',
                            borderRadius: '2px',
                            padding: '0rem',
                            height: '35px',
                            ':hover': {
                              borderColor: '#35475e',
                              backgroundColor: '#fff',
                            },
                            ':focus': {
                              borderColor: '#35475e !important',
                              backgroundColor: '#fff',
                            },
                            '::placeholder': {
                              fontStyle: 'Italic',
                            },
                          })}}
                          isSearchable={false}
                          options={genderOptions}
                          placeholder='Select gender'
                          name='gender'
                          onChange={(e: any) =>
                            setTenantData({
                              ...tenantData,
                              ['gender']: e?.value,
                            })
                          }
                        /> */}

                        <div className='test jobs owner'>
                          <div className='property-test d-none-multi-checbox'>
                            <div
                              className={`multi-select-container jobs-property ms-0 gender ${
                                tenantData?.gender?.length === 0 ? 'no-value' : 'has-value'
                              }`}
                            >
                              <MultiSelect
                                options={genderOptions}
                                value={tenantData?.gender}
                                onChange={(e: any) => {
                                  let newVal: any = e
                                  if (e?.length > 1) newVal = e.slice(1)
                                  {
                                    setTenantData({
                                      ...tenantData,
                                      ['gender']: newVal,
                                    })
                                  }
                                }}
                                labelledBy=''
                                overrideStrings={{
                                  allItemsAreSelected: '',
                                }}
                                hasSelectAll={false}
                                disableSearch
                                closeOnChangedValue={true}
                              />
                            </div>
                          </div>
                        </div>
                      </h4>
                    </div>
                    {/* ID NO. */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='labl-gry'> ID No. </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        <input
                          type='text'
                          className='form-control form-control-solid mytest '
                          placeholder='Enter ID number'
                          autoComplete='off'
                          name='idNo'
                          style={{backgroundColor: '#fff'}}
                          value={tenantData?.idNo}
                          onChange={(e) => {
                            const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                            setTenantData({
                              ...tenantData,
                              [e.target.name]: captilizeWord,
                            })
                          }}
                        />
                      </h4>
                    </div>
                    {/* Passport No. */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='labl-gry'> Passport No. </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        <input
                          type='text'
                          className='form-control form-control-solid mytest'
                          placeholder='Enter passport number'
                          autoComplete='off'
                          name='passportNo'
                          style={{backgroundColor: '#fff'}}
                          value={tenantData?.passportNo}
                          onChange={(e) => {
                            const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                            setTenantData({
                              ...tenantData,
                              [e.target.name]: captilizeWord,
                            })
                          }}
                        />
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className='row py-5 m-0 shadow-sm'
              style={{position: 'sticky', bottom: 0, background: '#fff', zIndex: 2}}
            >
              <div className='col-12 d-flex justify-content-between align-items-center'>
                <button
                  type='button'
                  className='btn btn-sm fw-bold red-hollow-btn ms-7'
                  onClick={() => {
                    handleClose(0)
                  }}
                >
                  <img src={redCross} height={17} width={17} className='me-3' /> Cancel
                </button>
                <button
                  type='submit'
                  className='btn btn-sm fw-bold px-2 green-hollow-btn justify-content-center ms-7 me-7'
                >
                  <img src={saveIcon} height={17} width={17} className='me-3' /> Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}

export default EditTenantModel
