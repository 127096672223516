import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import addWhite from '../../../../../../../../img/add-white.png'
import redCross from '../../../../../../../../img/remove.png'
import {SuccessToast, ErrorToast} from '../../../../../../../../apiCommon/helpers/Toast'
import {ApiPost} from '../../../../../../../../apiCommon/helpers/API/ApiData'
import {capitalizeFirstLetter} from '../../../../../../../../Utilities/utils'

interface AddChargeProps {
  show: boolean
  handleClose: () => void
  updateChargesList: () => void
}

const AddCharge: React.FC<AddChargeProps> = ({show, handleClose, updateChargesList}) => {
  const [charge, setCharge] = useState<{name: string; description: string}>({
    name: '',
    description: '',
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const addCharge = async () => {
    setIsLoading(true)

    const body = {
      name: charge?.name,
      description: charge?.description,
    }
    const url = 'corporate/lease_fee_list' // Specify the endpoint

    try {
      const response = await ApiPost(url, body)
      SuccessToast(response?.data?.message || 'Charge added successfully')
      updateChargesList()

      setCharge({name: '', description: ''})
    } catch (error: any) {
      ErrorToast(error.message || 'Failed to add charge')
    } finally {
      setIsLoading(false)
    }
  }

  const isDisabled = isLoading || charge.name.trim() === '' || charge.description.trim() === ''

  return (
    <Modal
      id='kt_modal_create_app'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px mx-auto'
      show={show}
      onHide={() => {
        handleClose()
        setCharge({name: '', description: ''})
      }}
      backdrop={true}
      size='sm'
    >
      <div className='modal-body px-7 pt-lg-3 pb-lg-3 modal-body-without-header'>
        <div
          className='d-flex align-items-center pb-4 mt-2'
          style={{borderBottom: '0.1rem solid #bec3cb'}}
        >
          <h2 className='pt-2 mb-0 white-dark-theme-color'>
            <b>Add New Charge</b>
          </h2>
          <button
            type='button'
            className='btn btn-sm fw-bold mx-3 red-hollow-btn ms-auto'
            onClick={handleClose}
            disabled={isLoading}
          >
            <img src={redCross} alt='Cancel' height={16} width={16} className='me-3' />
            Cancel
          </button>
        </div>

        <div className='row mx-3 mt-3'>
          <div className='col-12 px-5 ps-0 my-5 mt-3'>
            <div className='d-flex'>
              <label
                htmlFor='chargeName'
                className='labl-gry required mt-2 fnt-600'
                style={{minWidth: '130px'}}
              >
                Name
              </label>
              <input
                id='chargeName'
                type='text'
                className='form-control form-control-solid mytest bg-white'
                value={charge.name}
                onChange={(e) =>
                  setCharge({...charge, name: capitalizeFirstLetter(e.target.value)})
                }
                placeholder='Enter Charge Name..'
                style={{flex: 1}}
              />
            </div>
          </div>

          <div className='col-12 px-5 ps-0 my-5 mt-3'>
            <div className='d-flex'>
              <label
                htmlFor='chargeDescription'
                className='labl-gry required mt-2 fnt-600'
                style={{minWidth: '130px'}}
              >
                Description
              </label>

              <textarea
                className='form-control form-control-solid textarea-mytest bg-white'
                name='message'
                value={charge?.description}
                onChange={(e) => {
                  setCharge({...charge, description: capitalizeFirstLetter(e.target.value)})
                }}
                style={{height: '200px'}}
                placeholder={'Enter Charge Description..'}
              />
            </div>
          </div>
        </div>

        <div className='d-flex mx-3 justify-content-center mt-5 mb-10'>
          <button
            type='button'
            className='btn btn-sm fw-bold green-submit-btn ps-3 pe-10 status-w-250 justify-content-center'
            disabled={isDisabled}
            onClick={addCharge}
          >
            <img src={addWhite} alt='Add' height={16} width={16} className='me-3' />
            Add Charge
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default AddCharge
