import { MultiSelect } from "react-multi-select-component";
import { CustomValueRenderer } from "../../../../../../../../../Utilities/utils";

interface DropdownFilterProps {
    title: string;
    description: string;
    options: any[];
    selectedOptions: any[];
    onChange: (value: any[]) => void;
  }
  
  const DropdownFilter: React.FC<DropdownFilterProps> = ({
    title,
    description,
    options,
    selectedOptions,
    onChange,
  }) => {
    return (
      <div className={`multi-select-container jobs-property ${selectedOptions?.length === 0 ? 'no-value select' : 'has-value'}`}>
        <h3 className="text-dark fs-4 fnt-700 mb-1">{title}</h3>
        <p className="pe-13 fs-5 fnt-500 pt-2 labl-gry mb-3 light-dark-theme-color" style={{ width: '80%' }}>
          {description}
        </p>
        <MultiSelect
          options={options}
          value={selectedOptions}
          onChange={onChange}
          labelledBy={title}
          overrideStrings={{ allItemsAreSelected: `All ${title}` }}
          valueRenderer={CustomValueRenderer}
          disableSearch={title == 'Developments' ? false : true}
        />
      </div>
    );
  };

  export default DropdownFilter
  