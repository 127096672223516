import React from 'react'
import {Modal} from 'react-bootstrap'
import {useParams} from 'react-router-dom'

interface ConfirmationModalI {
  show: boolean
  onHide: any
  onAdd: any
  status: any
  isDisabled?: boolean
}

const ConfirmationModal = ({
  show,
  onHide,
  onAdd,
  status,
  isDisabled = false,
}: ConfirmationModalI) => {
  const {flg} = useParams()
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-450px mx-auto'
      show={show}
      onHide={() => {
        if (!isDisabled) onHide()
      }}
      backdrop={true}
    >
      <div className='modal-body p-0 modal-body-without-header'>
        <div
          className='row px-6 py-5 m-0'
          style={{position: 'sticky', top: 0, background: 'transparent', zIndex: 2}}
        >
          <div className='row d-flex flex-column align-items-center'>
            <div className='d-flex justify-content-center'>
              <h2 className='text-dark white-dark-theme-color my-4'>
                <b>{flg == '1' ? 'Add ' : 'Update'} Configuration</b>
              </h2>
            </div>

            <div>
              <p className='my-3 text-center text-dark white-dark-theme-color fs-3 fnt-400 mb-4'>
                You are about to {flg == '1' ? 'add ' : 'update'} this configuration.
              </p>

              <p className='my-3 text-center text-dark white-dark-theme-color fs-3 fnt-400 mb-4'>
                {status == 1 ? (
                  <>
                    {' '}
                    This configuration is set as ‘Active’ and will be applied automatically in all
                    new leases and bookings made for the selected properties.
                  </>
                ) : (
                  <>
                    This configuration is set as ‘Inactive’ and will not be applied automatically.
                  </>
                )}
              </p>
              {status == 0 && (
                <p className='my-3 text-center text-dark white-dark-theme-color fs-3 fnt-400 mb-4'>
                  However, you will be able to add this charge manually in any new lease or booking
                  made for the selected properties.
                </p>
              )}
            </div>
            <div className='d-flex mx-auto mt-7 mb-6'>
              <button
                type='button'
                className='btn btn-sm fw-bold green-submit-btn px-2 ms-auto justify-content-center status-w-120 me-3'
                onClick={() => {
                  onAdd()
                }}
                disabled={isDisabled}
              >
                {flg == '1' ? 'Add ' : 'Update'}
              </button>

              <button
                type='button'
                className='btn btn-sm fw-bold red-hollow-btn px-2 me-auto justify-content-center status-w-120 ms-3'
                onClick={() => {
                  onHide()
                }}
                disabled={isDisabled}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
