import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import Building from '../modules/building/Building'
import Area from '../modules/area/Area'
import Units from '../modules/units/Units'
import AreaCom from '../modules/area/AreaCom'
import BuildingCom from '../modules/building/BuildingCom'
import UnitsCom from '../modules/units/UnitsCom'
import UnitForm from '../modules/units/UnitForm'
import UnitComForm from '../modules/units/UnitComForm'
import UnitsBCom from '../modules/units/UnitsBCom'
import UnitBForm from '../modules/units/UnitsBForm'
import UnitMCOM from '../modules/units/UnitsMCom'
import BuildingCluster from '../modules/building/BuildingCluster'
import UnitComUpForm from '../modules/units/UnitComUpForm'
import UnitsCom1 from '../modules/units/UnitCom1'
import UnitComUpForm1 from '../modules/units/UnitComUpForm1'
import UnitComForm1 from '../modules/units/UnitComForm1'
import UnitUpForm from '../modules/units/UnitUpForm'
import Tenancies from '../modules/Tenancies/Tenancies'
import Tenants from '../modules/Tenants/Tenats'
import {BuildingDashboard} from '../pages/dashboard/BuildingDashboard'
import {CommunityDashboard} from '../pages/dashboard/CommunityDashboard'
import Community from '../modules/building/Community'
import CreateTenant from '../modules/Tenancies/CreateTenant'
import TenancyDetails from '../modules/Tenancies/TenancyDetails'
import PropertyDetails from '../modules/Tenancies/PropertyDetails'
import EditTenancy from '../modules/Tenancies/EditTenancy'
import ViewTenant from '../modules/Tenants/ViewTenant'
import EditTenant from '../modules/Tenants/EditTenant'
import ViewPreviousTenancy from '../modules/Tenancies/ViewPreviousTenancy'
import ViewPreviousTenants from '../modules/Tenants/ViewPreviousTenants'
import ViewMessage from '../modules/Tenants/ViewMessage'
import GlobalFinancials from '../modules/Financials/GlobalFinancials'
import FinancialExpenses from '../modules/Financials/FinancialExpenses'
import StatementReports from '../modules/Financials/StatementReports'
import PaymentReminders from '../modules/Financials/PaymentReminders'
import Announcement from '../modules/Announcement/New/Announcement'
import CreateAnnouncement from '../modules/Announcement/New/CreateAnnouncement'
// import CreateAnnouncement from '../modules/Announcement/CreateAnnouncement'
// import EditAnnouncement from '../modules/Announcement/EditAnnouncement'
// import ViewAnnouncement from '../modules/Announcement/ViewAnnouncement'
import CreateFacility from '../modules/Facility/CreateFacility'
import Facility from '../modules/Facility/Facility'
import EditFacility from '../modules/Facility/EditFacility'
import VendorManagement from '../modules/Vendor/Vendor'
import List from '../modules/Vendor/List'
import EditVendor from '../modules/Vendor/EditVendor'
import VendorSuppliersTab from '../modules/Vendor/VendorSuppliersTab'
import ManagementVendors from '../modules/Vendor/ManagementVendors'
import ManagementRequests from '../modules/Vendor/ManagementRequests'
import FacilityTeam from '../modules/Facility/FacilityTeam'
import FacilitymaintenancePlanner from '../modules/Facility/FacilitymaintenancePlanner'
import CreateList from '../modules/Vendor/CreateList'
import AddNewVendor from '../modules/Vendor/AddNewVendor'
import ViewVendor from '../modules/Vendor/ViewVendor'
import EditVendorDetails from '../modules/Vendor/EditVendorDetails'
import ApprovalWorkflow from '../modules/Vendor/ApprovalWorkflow'
// import ViewVendorList from '../modules/Vendor/ViewVendorList'
import EditVendorList from '../modules/Vendor/EditVendorList'
import RequestManagement from '../modules/Request/Request'
import ServiceManagement from '../modules/Request/Service'
import JobsManagement from '../modules/Request/Jobs'
import CreateService from '../modules/Request/CreateService'
import CreateServiceMain from '../modules/Request/CreateServiceMain'
import InternalTeam from '../modules/Request/InternalTeam'
import SelectProperty from '../modules/Request/SelectProperty'
import ServiceList from '../modules/Request/ServiceList'
import ApproveRequest from '../modules/Request/ApproveRequest'
import ServiceDetail from '../modules/Request/ServiceDetail'
import CustomList from '../modules/Request/CustomList'
import CreateVendorList from '../modules/Request/CreateVendorList'
import ReviewRequest from '../modules/Request/ReviewRequest'
import PaymentTab from '../modules/Request/PaymentTab'
import ViewRequest from '../modules/Request/ViewRequest'
import RequestLogTab from '../modules/Request/RequestLogTab'
import RequestsTab from '../modules/Request/RequestsTab'
import ScopeDetails from '../modules/Request/ScopeDetails'
import ServiceRequest from '../modules/Request/ServiceRequest'
import VendorList from '../modules/Request/VendorList'
import PropertiseServiceList from '../modules/Request/PropertiseServiceList'
import RequestCustomList from '../modules/Request/RequestCustomList'
import ServiceProviderList from '../modules/Request/ServiceProviderList'
import SelectProperties from '../modules/Request/SelectProperties'
import AssignRequest from '../modules/Request/AssignRequest'
import ExternalService from '../modules/Request/ExternalService'
import ViewScopeDetails from '../modules/Request/ViewScopeDetails'
import ExternalRequestLogTab from '../modules/Request/ExternalRequestLogTab'
import ExternalServiceDetails from '../modules/Request/ExternalServiceDetails'
import CreateServiceRequest from '../modules/Request/CreateServiceRequest'
import Chat from '../modules/chat/Chat'
import HandleTenantInternal from '../modules/Request/HandleTenantInternal'
import HandleTenantExternal from '../modules/Request/HandleTenantExternal'
import ServiceListTenant from '../modules/Request/ServiceListTenant'
import CustomListTenant from '../modules/Request/CustomListTenant'
import InternalTeamTenant from '../modules/Request/InternalTeamTenant'
import ServiceProviderListTenant from '../modules/Request/ServiceProviderListTenant'
import HandleDraft from '../modules/Request/HandleDraft'
import ServiceListDraft from '../modules/Request/ServiceListDraft'
import CustomListDraft from '../modules/Request/CustomListDraft'
import InternalTeamDraft from '../modules/Request/InternalTeamDraft'
import ServiceProviderDraft from '../modules/Request/ServiceProviderDraft'
import SelectPropertyDraft from '../modules/Request/SelectPropertyDraft'
import ViewTenantRequest from '../modules/Request/ViewTenantRequest'
import Settings from '../modules/settings/Settings'
import PropertyIncomeItems from '../modules/settings/PropertyIncomeItems'
import PropertyIncomeItemsComm from '../modules/settings/PropertyIncomeItemsComm'
import BuildingsIncomeItems from '../modules/settings/BuildingsIncomeItems'
import LatePaymentFee from '../modules/settings/LatePaymentFee'
import LatePaymentFeeComm from '../modules/settings/LatePaymentFeeComm'
import BuildingsLatePaymentFee from '../modules/settings/BuildingLatePaymentFee'
import Accounts from '../modules/settings/Accounts'
import FinancialIncome from '../modules/propertyProfile/PropertyProfileFinancials/FinancialIncome'
import Invoicing from '../modules/settings/Invoicing'
import InvoiceDesign from '../modules/settings/InvoiceDesign'
import Receipt from '../modules/settings/Receipt'
import ReceiptDesign from '../modules/settings/ReceiptDesign'
import CreditNote from '../modules/settings/CreditNote'
import CreditNoteDesign from '../modules/settings/CreditNoteDesign'
import DebitNoteDesign from '../modules/settings/DebitNoteDesign'
import DebitNote from '../modules/settings/DebitNote'
import Refund from '../modules/settings/Refund'
import RefundDesign from '../modules/settings/RefundDesign'
import TenancyList from '../modules/propertyProfile/PropertyProfileTenancy/Tenancy'
import TenantsProperty from '../modules/propertyProfile/PropertyProfileTenant/Tenants'
import Annoucements from '../modules/propertyProfile/PropertyProfileAnnoucements/Annoucements'
import Requests from '../modules/propertyProfile/PropertyProfileRequests/Requests'
import ViewRequestProperty from '../modules/propertyProfile/PropertyProfileRequests/ViewRequest'
// import RenewTenancy from '../modules/Tenancies/RenewTenancy'
import Feedback from '../modules/Feedback/Feedback'
import ViewFeedback from '../modules/Feedback/ViewFeedback'
import AddTenant from '../modules/Tenants/AddNewTenant'
import RequestView from '../modules/Tenants/RequestView'
import CreateTenancy from '../modules/Tenancies/NewCreateTenancy'
import ViewTenancy from '../modules/Tenancies/ViewEditTenancy'
import RenewTenancy from '../modules/Tenancies/NewRenewTenancy'
import TerminateTenancy from '../modules/Tenancies/TerminateTenancy'
import RolesAndPermissions from '../modules/UserSettings/RolesAndPermissions'
import AddRole from '../modules/UserSettings/AddRole'
import ViewRole from '../modules/UserSettings/ViewRole'
import EditRole from '../modules/UserSettings/EditRole'
import UsersInRole from '../modules/UserSettings/UsersInRole'
import GlobalUsers from '../modules/UserSettings/Users/GlobalUsers'
import ViewUser from '../modules/UserSettings/Users/ViewUser'
import Workflows from '../modules/WorkflowSettings/Workflows'
import LeasingWorkflows from '../modules/WorkflowSettings/LeasingWorkflow'
import TypeLeasing from '../modules/WorkflowSettings/TypeLeasing'
import ApprovalCreateTenancy from '../modules/Tenancies/ApprovalCreateTenancy'
import RenewalTerminationRequest from '../modules/Tenancies/RenewalTerminationRequest'
import Vendors from '../modules/VendorManagement/Vendors'
import AddVendor from '../modules/VendorManagement/AddVendor'
import ViewEditVendor from '../modules/VendorManagement/ViewEditVendor'
import ManageLists from '../modules/VendorManagement/ManageLists'
import ServicesLibrary from '../modules/settings/MaintenanceSetting/ServiceLibrary/ServicesLibrary'
import Operations from '../modules/settings/MaintenanceSetting/Operations/Operations'
import ManageListsServices from '../modules/settings/MaintenanceSetting/ServiceLibrary/ManageLists'
import Maintenance from '../modules/Maintenance/Maintenance'
import CreateRequest from '../modules/Maintenance/CreateRequest'
import ViewEditRequest from '../modules/Maintenance/ViewEditRequest'
import MaintenanceFlows from '../modules/WorkflowSettings/Maintenance/MaintenanceFlow'
import TypeMaintenance from '../modules/WorkflowSettings/Maintenance/TypeMaintenance'
import NewTypeMaintenance from '../modules/WorkflowSettings/Maintenance/NewTypeMaintenance'
import ServiceJob from '../modules/Maintenance/MaintenanceJobs/ServiceJob'
import Properties from '../modules/Properties/Properties'
import PurchaseOrderGenerator from '../modules/settings/ProcurementSettings/PurchaseOrder/PurchaseOrderGenearator'
import PODesign from '../modules/settings/ProcurementSettings/PurchaseOrder/PurchaseOrderDesign'
import POTemplate from '../modules/Maintenance/POTemplate'
import ViewPOTemplate from '../modules/Maintenance/ViewPOTemplate'
import BuildingDetailList from '../modules/Properties/components/BuildingDetailList'
import CommunityDetailList from '../modules/Properties/components/CommunityDetailList'
import FloorDetailList from '../modules/Properties/components/FloorDetailList'
import AddFloorProperty from '../modules/Properties/components/AddFloorProperty'
import AddGlobalProperty from '../modules/Properties/components/AddGlobalProperty'
import UnitBuildingClusterDetailList from '../modules/Properties/components/UnitBuildingClusterDetailList'
import CommunityBuildingDetailList from '../modules/Properties/components/CommunityBuildingDetailList'
import BuildingFloorDetailList from '../modules/Properties/components/BuildingFloorDetailList'
import GroupDetailList from '../modules/Properties/components/GroupDetailList'
import AddPropertyDevelopment from '../modules/Properties/components/AddPropertyDevelopment'
import AddCommunityProperty from '../modules/Properties/components/AddCommunityProperty'
import CommunityBuildingFloorDetailList from '../modules/Properties/components/CommunityBuildingFloorDetailList'
import CommunityGroupFloorDetailList from '../modules/Properties/components/CommunityGroupFloorDetailList'
import ExpensesLists from '../modules/settings/PaymentItems/PaymentItemsLists'
import ExpensesItems from '../modules/settings/PaymentItems/PaymentItems'
import ItemsOfList from '../modules/settings/PaymentItems/ItemsOfList'
import Expenses from '../modules/Financials/Expenses/Expenses'
import AddExpense from '../modules/Financials/Expenses/AddExpense'
import ViewEditExpense from '../modules/Financials/Expenses/ViewEditExpense'
import EditPropertyDevelopment from '../modules/Properties/components/EditPropertyDevelopment'
import Dashboard from '../modules/Dashboard/Dashboard'
import EditProperty from '../modules/Properties/components/EditProperty '
import EditBuilding from '../modules/Properties/components/EditBuilding'
import SubTypeMaintenance from '../modules/WorkflowSettings/Maintenance/SubTypeMaintenance'
import TenantsNew from '../modules/Tenants/New/Tenant'
import ViewTenantNew from '../modules/Tenants/New/components/ViewTenant'
import CRMResidents from '../modules/CRM/CRMResidents'
import ViewResidentRequest from '../modules/CRM/ViewResidentRequest'
import ViewResidentRequestOfMaintenance from '../modules/Maintenance/CRM/ViewResidentRequest'
import ViewResidentRequestOfLeases from '../modules/Tenancies/CRM/ViewResidentRequest'
import CRMResidentsOfFinancials from '../modules/Financials/CRM/CRMResidents'
import ViewResidentRequestOfFinancials from '../modules/Financials/CRM/ViewResidentRequest'
import Incomes from '../modules/Financials/Incomes/Incomes'
import ViewEditIncome from '../modules/Financials/Incomes/ViewEditIncome'
import TicketAssignment from '../modules/settings/CRM/TicketAssignments'
import AddAssignmentRule from '../modules/settings/CRM/AddAssignmentRule'
import TenantRequest from '../modules/settings/CRM/TenantRequest'
import TenantSubRequest from '../modules/settings/CRM/TenantSubRequest'
import PropertyPrpfile from '../modules/Properties/components/PropertyProfile'
import Channels from '../modules/settings/CRM/Channels/Channels'
import EmailsChannels from '../modules/settings/CRM/Channels/Emails/Emails'
import ConnectEmailsDomain from '../modules/settings/CRM/Channels/Emails/ConnectEmailDomain'
import EmailTemplates from '../modules/settings/CRM/EmailTemplates/ListingEmailTemplates'
import SelectTemplates from '../modules/settings/CRM/EmailTemplates/SelectTemplates'
import Template002 from '../modules/settings/CRM/EmailTemplates/Templates/Template002'
import Template001 from '../modules/settings/CRM/EmailTemplates/Templates/Template001'
import Owners from '../modules/Owners/Owners'
import ViewOwner from '../modules/Owners/components/ViewOwner'
import AddIncomePayment from '../modules/Financials/Incomes/AddIncomePayment'
import GenerateInvoice from '../modules/Financials/Incomes/GenerateInvoice'
import AddInvoice from '../modules/Financials/Incomes/SubTabs/Invoices/AddInvoice'
import EditInvoice from '../modules/Financials/Incomes/SubTabs/Invoices/EditInvoice'
import RaiseReceipt from '../modules/Financials/Incomes/SubTabs/Receipts/RaiseReceipt'
import ViewReceipt from '../modules/Financials/Incomes/SubTabs/Receipts/ViewReceipt'
import EditReceipt from '../modules/Financials/Incomes/SubTabs/Receipts/EditReceipt'
import ViewInvoice from '../modules/Financials/Incomes/SubTabs/Invoices/ViewInvoice'
import IssueCreditNote from '../modules/Financials/Incomes/SubTabs/CreditDebitNotes/CreditNote/IssueCreditNote'
import ViewCreditNote from '../modules/Financials/Incomes/SubTabs/CreditDebitNotes/CreditNote/ViewCreditNote'
import EditCreditNote from '../modules/Financials/Incomes/SubTabs/CreditDebitNotes/CreditNote/EditCreditNote'
import IssueDebitNote from '../modules/Financials/Incomes/SubTabs/CreditDebitNotes/DebitNote/IssueDebitNote'
import ViewDebitNote from '../modules/Financials/Incomes/SubTabs/CreditDebitNotes/DebitNote/ViewDebitNote'
import EditDebitNote from '../modules/Financials/Incomes/SubTabs/CreditDebitNotes/DebitNote/EditDebitNote'
import ShareNote from '../modules/Financials/Incomes/SubTabs/CreditDebitNotes/components/ShareNote'
import LatePaymentFeeListing from '../modules/settings/LatePaymentFee/LatePaymentFeeListing'
import Communications from '../modules/settings/Communications/Communications'
import TypeListing from '../modules/settings/Communications/TypeListing'
import ResidentialLeasing from '../modules/settings/Leasing/Residential/ResidentialLeasing'
import AnnualLeases from '../modules/settings/Leasing/Residential/AnnualLeases/AnnualLeases'
import Configurations from '../modules/settings/Leasing/Residential/AnnualLeases/SubTabs/LeaseCharges/Configurations'
import AddConfiguration from '../modules/settings/Leasing/Residential/AnnualLeases/SubTabs/LeaseCharges/OtherCharges/AddConfiguration'
import AddBookingFeeConfiguration from '../modules/settings/Leasing/Residential/AnnualLeases/SubTabs/LeaseCharges/BookingFee/AddBookingFeeConfiguration'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {/* Root Dashboard */}

        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<Dashboard />} />
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        <Route path='building-dashboard' element={<BuildingDashboard />} />
        <Route path='community-dashboard' element={<CommunityDashboard />} />
        <Route path='building1' element={<BuilderPageWrapper />} />
        <Route path='area1' element={<MenuTestPage />} />
        <Route
          path='chat'
          element={
            <SuspensedView>
              <Chat />
            </SuspensedView>
          }
        />

        {/* Settings */}
        <Route
          path='settings'
          element={
            <SuspensedView>
              <Settings />
            </SuspensedView>
          }
        />
        <Route
          path='settings/buildings-income-items'
          element={
            <SuspensedView>
              <PropertyIncomeItems />
            </SuspensedView>
          }
        />
        <Route
          path='settings/communities-income-items'
          element={
            <SuspensedView>
              <PropertyIncomeItemsComm />
            </SuspensedView>
          }
        />
        <Route
          path='settings/property-income-items/:id'
          element={
            <SuspensedView>
              <BuildingsIncomeItems />
            </SuspensedView>
          }
        />
        <Route
          path='settings/buildings-late-payment-fee'
          element={
            <SuspensedView>
              <LatePaymentFee />
            </SuspensedView>
          }
        />
        <Route
          path='settings/communities-late-payment-fee'
          element={
            <SuspensedView>
              <LatePaymentFeeComm />
            </SuspensedView>
          }
        />
        <Route
          path='settings/late-payment-fee/:id'
          element={
            <SuspensedView>
              <BuildingsLatePaymentFee />
            </SuspensedView>
          }
        />
        <Route
          path='settings/accounts'
          element={
            <SuspensedView>
              <Accounts />
            </SuspensedView>
          }
        />
        <Route
          path='settings/invoicing'
          element={
            <SuspensedView>
              <Invoicing />
            </SuspensedView>
          }
        />
        <Route
          path='settings/invoice-design'
          element={
            <SuspensedView>
              <InvoiceDesign />
            </SuspensedView>
          }
        />
        <Route
          path='settings/receipt'
          element={
            <SuspensedView>
              <Receipt />
            </SuspensedView>
          }
        />
        <Route
          path='settings/receipt-design'
          element={
            <SuspensedView>
              <ReceiptDesign />
            </SuspensedView>
          }
        />
        <Route
          path='settings/credit-note'
          element={
            <SuspensedView>
              <CreditNote />
            </SuspensedView>
          }
        />
        <Route
          path='settings/credit-note-design'
          element={
            <SuspensedView>
              <CreditNoteDesign />
            </SuspensedView>
          }
        />
        <Route
          path='settings/debit-note'
          element={
            <SuspensedView>
              <DebitNote />
            </SuspensedView>
          }
        />
        <Route
          path='settings/debit-note-design'
          element={
            <SuspensedView>
              <DebitNoteDesign />
            </SuspensedView>
          }
        />
        <Route
          path='settings/refund'
          element={
            <SuspensedView>
              <Refund />
            </SuspensedView>
          }
        />
        <Route
          path='settings/refund-design'
          element={
            <SuspensedView>
              <RefundDesign />
            </SuspensedView>
          }
        />
        <Route
          path='settings/roles-and-permissions'
          element={
            <SuspensedView>
              <RolesAndPermissions />
            </SuspensedView>
          }
        />
        <Route
          path='settings/add-role'
          element={
            <SuspensedView>
              <AddRole />
            </SuspensedView>
          }
        />
        <Route
          path='settings/view-role/:id'
          element={
            <SuspensedView>
              <ViewRole />
            </SuspensedView>
          }
        />
        <Route
          path='settings/edit-role/:id'
          element={
            <SuspensedView>
              <EditRole />
            </SuspensedView>
          }
        />
        <Route
          path='settings/view-role/:id/users'
          element={
            <SuspensedView>
              <UsersInRole />
            </SuspensedView>
          }
        />
        <Route
          path='settings/users'
          element={
            <SuspensedView>
              <GlobalUsers />
            </SuspensedView>
          }
        />

        <Route
          path='settings/users/view-user'
          element={
            <SuspensedView>
              <ViewUser />
            </SuspensedView>
          }
        />

        <Route
          path='settings/workflows'
          element={
            <SuspensedView>
              <Workflows />
            </SuspensedView>
          }
        />
        <Route
          path='settings/workflows/leasing/:id'
          element={
            <SuspensedView>
              <LeasingWorkflows />
            </SuspensedView>
          }
        />
        <Route
          path='settings/workflows/leasing/:mainId/:flg/:id'
          element={
            <SuspensedView>
              <TypeLeasing />
            </SuspensedView>
          }
        />
        <Route
          path='settings/workflows/maintenance/:id'
          element={
            <SuspensedView>
              <MaintenanceFlows />
            </SuspensedView>
          }
        />
        <Route
          path='settings/workflows/maintenance/:mainId/:flg/:id'
          element={
            <SuspensedView>
              <NewTypeMaintenance />
            </SuspensedView>
          }
        />
        <Route
          path='settings/workflows/maintenance/sub-workflows/:mainId/:flg/:id/:typeName'
          element={
            <SuspensedView>
              <SubTypeMaintenance />
            </SuspensedView>
          }
        />
        <Route
          path='settings/maintenance/services-library'
          element={
            <SuspensedView>
              <ServicesLibrary />
            </SuspensedView>
          }
        />
        <Route
          path='settings/maintenance/services/manage-lists'
          element={
            <SuspensedView>
              <ManageListsServices />
            </SuspensedView>
          }
        />
        <Route
          path='settings/maintenance/operations'
          element={
            <SuspensedView>
              <Operations />
            </SuspensedView>
          }
        />

        {/* Procurement Settings */}
        <Route
          path='settings/procurement/purchase-order'
          element={
            <SuspensedView>
              <PurchaseOrderGenerator />
            </SuspensedView>
          }
        />
        <Route
          path='settings/procurement/purchase-order-design'
          element={
            <SuspensedView>
              <PODesign />
            </SuspensedView>
          }
        />

        {/* Settings - Expenses */}
        <Route
          path='settings/payment-items-lists'
          element={
            <SuspensedView>
              <ExpensesLists />
            </SuspensedView>
          }
        />

        <Route
          path='settings/payment-items'
          element={
            <SuspensedView>
              <ExpensesItems />
            </SuspensedView>
          }
        />

        <Route
          path='settings/payment-items-lists/:id'
          element={
            <SuspensedView>
              <ItemsOfList />
            </SuspensedView>
          }
        />

        <Route
          path='settings/crm-residents/channels'
          element={
            <SuspensedView>
              <Channels />
            </SuspensedView>
          }
        />

        <Route
          path='settings/crm-residents/channels/emails'
          element={
            <SuspensedView>
              <EmailsChannels />
            </SuspensedView>
          }
        />

        <Route
          path='settings/crm-residents/channels/emails/connect-email-domain'
          element={
            <SuspensedView>
              <ConnectEmailsDomain />
            </SuspensedView>
          }
        />

        <Route
          path='settings/crm-residents/channels/emails/connect-email-domain/:id'
          element={
            <SuspensedView>
              <ConnectEmailsDomain />
            </SuspensedView>
          }
        />

        <Route
          path='settings/crm-residents/email-templates'
          element={
            <SuspensedView>
              <EmailTemplates />
            </SuspensedView>
          }
        />

        <Route
          path='settings/crm-residents/email-templates/select'
          element={
            <SuspensedView>
              <SelectTemplates />
            </SuspensedView>
          }
        />

        <Route
          path='settings/crm-residents/email-templates/select/:id'
          element={
            <SuspensedView>
              <SelectTemplates />
            </SuspensedView>
          }
        />

        <Route
          path='settings/crm-residents/email-templates/select/template-02'
          element={
            <SuspensedView>
              <Template002 />
            </SuspensedView>
          }
        />

        <Route
          path='settings/crm-residents/email-templates/select/template-02/:type'
          element={
            <SuspensedView>
              <Template002 />
            </SuspensedView>
          }
        />

        <Route
          path='settings/crm-residents/email-templates/select/template-01'
          element={
            <SuspensedView>
              <Template001 />
            </SuspensedView>
          }
        />

        <Route
          path='settings/crm-residents/email-templates/select/template-01/:type'
          element={
            <SuspensedView>
              <Template001 />
            </SuspensedView>
          }
        />

        <Route
          path='/feedback'
          element={
            <SuspensedView>
              <Feedback />
            </SuspensedView>
          }
        />
        <Route
          path='/feedback/:id'
          element={
            <SuspensedView>
              <ViewFeedback />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/building'
          element={
            <SuspensedView>
              <Building />
            </SuspensedView>
          }
        />
        <Route
          path='/community'
          element={
            <SuspensedView>
              <Community />
            </SuspensedView>
          }
        />
        <Route
          path='/tenancies'
          element={
            <SuspensedView>
              <Tenancies />
            </SuspensedView>
          }
        />
        <Route
          path='/tenant/:id'
          element={
            <SuspensedView>
              <ViewTenant />
            </SuspensedView>
          }
        />
        <Route
          path='/add-tenant'
          element={
            <SuspensedView>
              <AddTenant />
            </SuspensedView>
          }
        />
        <Route
          path='/edit-tenant/:id'
          element={
            <SuspensedView>
              <EditTenant />
            </SuspensedView>
          }
        />
        {/* <Route
          path='/edit-announcement/:id'
          element={
            <SuspensedView>
              <EditAnnouncement />
            </SuspensedView>
          }
        /> */}
        <Route
          path='/viewmessage/:id'
          element={
            <SuspensedView>
              <ViewMessage />
            </SuspensedView>
          }
        />
        <Route
          path='/tenants'
          element={
            <SuspensedView>
              <Tenants />
            </SuspensedView>
          }
        />

        {/* Financials */}
        <Route
          path='/financials-income'
          element={
            <SuspensedView>
              <GlobalFinancials />
            </SuspensedView>
          }
        />
        <Route
          path='/financials-expenses'
          element={
            <SuspensedView>
              <Expenses />
            </SuspensedView>
          }
        />
        <Route
          path='/financials/add-expense/:flg'
          element={
            <SuspensedView>
              <AddExpense />
            </SuspensedView>
          }
        />
        <Route
          path='/financials/add-expense/:flg/:id'
          element={
            <SuspensedView>
              <AddExpense />
            </SuspensedView>
          }
        />
        <Route
          path='/financials/view-expense/:id'
          element={
            <SuspensedView>
              <ViewEditExpense />
            </SuspensedView>
          }
        />
        <Route
          path='/finance-crm-residents'
          element={
            <SuspensedView>
              <CRMResidentsOfFinancials />
            </SuspensedView>
          }
        />

        <Route
          path='/finance-resident-request/:id'
          element={
            <SuspensedView>
              <ViewResidentRequestOfFinancials />
            </SuspensedView>
          }
        />
        <Route
          path='/financials-statement-reports'
          element={
            <SuspensedView>
              <StatementReports />
            </SuspensedView>
          }
        />
        <Route
          path='/financials-payment-reminders'
          element={
            <SuspensedView>
              <PaymentReminders />
            </SuspensedView>
          }
        />
        <Route
          path='/buildingcluster/:id/:id'
          element={
            <SuspensedView>
              <BuildingCluster />
            </SuspensedView>
          }
        />
        <Route
          path='/buildingCom'
          element={
            <SuspensedView>
              <BuildingCom />
            </SuspensedView>
          }
        />
        <Route
          path='/create-tenancy'
          element={
            <SuspensedView>
              <CreateTenant />
            </SuspensedView>
          }
        />
        <Route
          path='/renew-tenancy/:flg/:id/:tenancyId'
          element={
            <SuspensedView>
              <RenewTenancy />
            </SuspensedView>
          }
        />

        <Route
          path='/create-tenancy/:flg'
          element={
            <SuspensedView>
              <CreateTenant />
            </SuspensedView>
          }
        />
        <Route
          path='/create-tenancy/:flg/:id'
          element={
            <SuspensedView>
              <CreateTenant />
            </SuspensedView>
          }
        />
        <Route
          path='/create-tenant'
          element={
            <SuspensedView>
              <CreateTenant />
            </SuspensedView>
          }
        />
        <Route
          path='/create-tenant/:flg'
          element={
            <SuspensedView>
              <CreateTenant />
            </SuspensedView>
          }
        />
        <Route
          path='/create-tenant/:flg/:id/:tenancyId'
          element={
            <SuspensedView>
              <CreateTenant />
            </SuspensedView>
          }
        />

        <Route
          path='/tenancy/:action/:flg'
          element={
            <SuspensedView>
              <CreateTenancy />
            </SuspensedView>
          }
        />

        <Route
          path='/create-approve-tenancy/:flg'
          element={
            <SuspensedView>
              <ApprovalCreateTenancy />
            </SuspensedView>
          }
        />

        <Route
          path='/create-approve-tenancy/:flg/:unitId/:tenancyId'
          element={
            <SuspensedView>
              <ApprovalCreateTenancy />
            </SuspensedView>
          }
        />

        <Route
          path='/lease-request/:id/:unitId/:tenancyId/:type'
          element={
            <SuspensedView>
              <RenewalTerminationRequest />
            </SuspensedView>
          }
        />

        <Route
          path='/tenancy/:action/:flg/:unitId/:tenancyId'
          element={
            <SuspensedView>
              <CreateTenancy />
            </SuspensedView>
          }
        />

        <Route
          path='/tenancy-view/:flg/:id/:tenancyId'
          element={
            <SuspensedView>
              <ViewTenancy />
            </SuspensedView>
          }
        />

        <Route
          path='/tenancy-termination/:flg/:id/:tenancyId'
          element={
            <SuspensedView>
              <TerminateTenancy />
            </SuspensedView>
          }
        />

        <Route
          path='/tenancy-details/:tenantId/:tenancyId'
          element={
            <SuspensedView>
              <TenancyDetails />
            </SuspensedView>
          }
        />
        <Route
          path='/overViewData/:unitId'
          element={
            <SuspensedView>
              <PropertyDetails />
            </SuspensedView>
          }
        />

        <Route
          path='/overViewData/:unitId/financials/income'
          element={
            <SuspensedView>
              <FinancialIncome />
            </SuspensedView>
          }
        />

        <Route
          path='/overViewData/:unitId/tenancy'
          element={
            <SuspensedView>
              <TenancyList />
            </SuspensedView>
          }
        />

        <Route
          path='/overViewData/:unitId/tenant'
          element={
            <SuspensedView>
              <TenantsProperty />
            </SuspensedView>
          }
        />

        <Route
          path='/overViewData/:unitId/annoucements'
          element={
            <SuspensedView>
              <Annoucements />
            </SuspensedView>
          }
        />

        <Route
          path='/overViewData/:unitId/requests'
          element={
            <SuspensedView>
              <Requests />
            </SuspensedView>
          }
        />

        <Route
          path='/overViewData/:unitId/view/:requestId/:reqType'
          element={
            <SuspensedView>
              <ViewRequestProperty />
            </SuspensedView>
          }
        />

        <Route
          path='/view-request/:requestId/:reqType'
          element={
            <SuspensedView>
              <RequestView />
            </SuspensedView>
          }
        />

        <Route
          path='/tenancy-details/:tenancyId/payment-rent'
          element={
            <SuspensedView>
              <TenancyDetails />
            </SuspensedView>
          }
        />
        <Route
          path='/edit-tenancy/:tenancyId'
          element={
            <SuspensedView>
              <EditTenancy />
            </SuspensedView>
          }
        />
        <Route
          path='/unitform/:propertyId/:floorId'
          element={
            <SuspensedView>
              <UnitForm />
            </SuspensedView>
          }
        />
        <Route
          path='/unitbform/:propertyId/:floorId'
          element={
            <SuspensedView>
              <UnitBForm />
            </SuspensedView>
          }
        />
        <Route
          path='/unitComform/:propertyId/:floorId'
          element={
            <SuspensedView>
              <UnitComForm />
            </SuspensedView>
          }
        />
        <Route
          path='/unitComform1/:propertyId/:floorId'
          element={
            <SuspensedView>
              <UnitComForm1 />
            </SuspensedView>
          }
        />
        <Route
          path='/viewprevioustenancy/:id'
          element={
            <SuspensedView>
              <ViewPreviousTenancy />
            </SuspensedView>
          }
        />
        <Route
          path='/viewprevioustenant/:id'
          element={
            <SuspensedView>
              <ViewPreviousTenants />
            </SuspensedView>
          }
        />
        <Route
          path='/unitComUpform/:propertyId/:floorId/:id'
          element={
            <SuspensedView>
              <UnitComUpForm />
            </SuspensedView>
          }
        />
        <Route
          path='/unitUpform/:propertyId/:floorId/:id'
          element={
            <SuspensedView>
              <UnitUpForm />
            </SuspensedView>
          }
        />
        <Route
          path='/unitComUpform1/:propertyId/:floorId/:id'
          element={
            <SuspensedView>
              <UnitComUpForm1 />
            </SuspensedView>
          }
        />
        <Route
          path='/area/:id'
          element={
            <SuspensedView>
              <Area />
            </SuspensedView>
          }
        />
        <Route
          path='/areaCom/:id'
          element={
            <SuspensedView>
              <AreaCom />
            </SuspensedView>
          }
        />
        {/* <Route
          path='/viewannouncement/:id'
          element={
            <SuspensedView>
              <ViewAnnouncement />
            </SuspensedView>
          }
        /> */}

        <Route
          path='units/:propertyId/:floorId'
          element={
            <SuspensedView>
              <Units />
            </SuspensedView>
          }
        />
        <Route
          path='unitsmcom/:propertyId/:floorId'
          element={
            <SuspensedView>
              <UnitMCOM />
            </SuspensedView>
          }
        />
        <Route
          path='unitbcoms/:propertyId/:floorId'
          element={
            <SuspensedView>
              <UnitsBCom />
            </SuspensedView>
          }
        />
        <Route
          path='unitscom/:propertyId/:floorId'
          element={
            <SuspensedView>
              <UnitsCom />
            </SuspensedView>
          }
        />
        <Route
          path='unitscom1/:propertyId/:floorId'
          element={
            <SuspensedView>
              <UnitsCom1 />
            </SuspensedView>
          }
        />

        {/* Annoucements */}
        <Route
          path='/announcements'
          element={
            <SuspensedView>
              <Announcement />
            </SuspensedView>
          }
        />

        <Route
          path='/create-announcement/:flg'
          element={
            <SuspensedView>
              <CreateAnnouncement />
            </SuspensedView>
          }
        />

        <Route
          path='/create-announcement/:flg/:id'
          element={
            <SuspensedView>
              <CreateAnnouncement />
            </SuspensedView>
          }
        />

        <Route
          path='/create-announcements'
          element={
            <SuspensedView>
              <CreateAnnouncement />
            </SuspensedView>
          }
        />

        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='/facility'
          element={
            <SuspensedView>
              <Facility />
            </SuspensedView>
          }
        />
        <Route
          path='/facility-team'
          element={
            <SuspensedView>
              <FacilityTeam />
            </SuspensedView>
          }
        />
        <Route
          path='/facility-maintenancePlanner'
          element={
            <SuspensedView>
              <FacilitymaintenancePlanner />
            </SuspensedView>
          }
        />
        <Route
          path='/create-facility'
          element={
            <SuspensedView>
              <CreateFacility />
            </SuspensedView>
          }
        />
        <Route
          path='/edit-facility/:id'
          element={
            <SuspensedView>
              <EditFacility />
            </SuspensedView>
          }
        />
        {/* Vendor Management */}
        <Route
          path='/vendor-management'
          element={
            <SuspensedView>
              <Vendors />
            </SuspensedView>
          }
        />
        <Route
          path='/add-vendor/:flg'
          element={
            <SuspensedView>
              <AddVendor />
            </SuspensedView>
          }
        />
        <Route
          path='/add-vendor/:flg/:id'
          element={
            <SuspensedView>
              <AddVendor />
            </SuspensedView>
          }
        />
        <Route
          path='/vendor/:id'
          element={
            <SuspensedView>
              <ViewEditVendor />
            </SuspensedView>
          }
        />
        <Route
          path='/manage-vendors'
          element={
            <SuspensedView>
              <ManageLists />
            </SuspensedView>
          }
        />
        {/*  */}
        <Route
          path='/vendor-management/serviceproviders'
          element={
            <SuspensedView>
              <VendorManagement />
            </SuspensedView>
          }
        />
        <Route
          path='/vendor-management/suppliers'
          element={
            <SuspensedView>
              <VendorSuppliersTab />
            </SuspensedView>
          }
        />
        <Route
          path='/vendor-management/management-list'
          element={
            <SuspensedView>
              <List />
            </SuspensedView>
          }
        />
        <Route
          path='/vendor-management/management-list/create-list'
          element={
            <SuspensedView>
              <CreateList />
            </SuspensedView>
          }
        />
        <Route
          path='/vendor-management/management-list/view-vendor/:id'
          element={
            <SuspensedView>
              <EditVendor />
            </SuspensedView>
          }
        />
        <Route
          path='/vendor-management/management-list/edit-vendor/:id'
          element={
            <SuspensedView>
              <EditVendorList />
            </SuspensedView>
          }
        />
        <Route
          path='/vendor-management/management-vendors'
          element={
            <SuspensedView>
              <ManagementVendors />
            </SuspensedView>
          }
        />
        <Route
          path='/vendor-management/management-vendors/add-vendors'
          element={
            <SuspensedView>
              <AddNewVendor />
            </SuspensedView>
          }
        />
        <Route
          path='/vendor-management/management-vendors/view-vendors/:id'
          element={
            <SuspensedView>
              <ViewVendor />
            </SuspensedView>
          }
        />
        <Route
          path='/vendor-management/management-vendors/view-vendors/edit-vendor/:id'
          element={
            <SuspensedView>
              <EditVendorDetails />
            </SuspensedView>
          }
        />
        <Route
          path='/vendor-management/management-requests'
          element={
            <SuspensedView>
              <ManagementRequests />
            </SuspensedView>
          }
        />
        <Route
          path='/vendor-management/management-vendors/approvalWorkflow/:id'
          element={
            <SuspensedView>
              <ApprovalWorkflow />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/quotation'
          element={
            <SuspensedView>
              <RequestManagement />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service'
          element={
            <SuspensedView>
              <ServiceManagement />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/jobs'
          element={
            <SuspensedView>
              <JobsManagement />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/create-request-service/internal-team'
          element={
            <SuspensedView>
              <InternalTeam />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/create-request-service/assign-request'
          element={
            <SuspensedView>
              <AssignRequest />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/jobs/:id/service-request'
          element={
            <SuspensedView>
              <ServiceRequest />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/create-request-service/select-property'
          element={
            <SuspensedView>
              <SelectProperty />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/create-request-service/select-list'
          element={
            <SuspensedView>
              <ServiceList />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/create-request-service/custom-list'
          element={
            <SuspensedView>
              <CustomList />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/create-request-service/approve-request/:id'
          element={
            <SuspensedView>
              <ApproveRequest />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/create-request-service/service-detail/:serviceId'
          element={
            <SuspensedView>
              <ServiceDetail />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/external-detail/:id'
          element={
            <SuspensedView>
              <ExternalServiceDetails />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/external-service-details/:serviceId/:flg'
          element={
            <SuspensedView>
              <ViewScopeDetails />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/create-request-service/service-detail/scope-detail/:id'
          element={
            <SuspensedView>
              <ViewRequest />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/external-request-log-tab/:id'
          element={
            <SuspensedView>
              <ExternalRequestLogTab />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/external-service/:id'
          element={
            <SuspensedView>
              <ExternalService />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/create-request-service/payment-tab/:id'
          element={
            <SuspensedView>
              <PaymentTab />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/create-request-service/request-log-tab/:id'
          element={
            <SuspensedView>
              <RequestLogTab />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/service-provider-list'
          element={
            <SuspensedView>
              <ServiceProviderList />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/select-properties'
          element={
            <SuspensedView>
              <SelectProperties />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/create-request-service/request-tab'
          element={
            <SuspensedView>
              <RequestsTab />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/vendor-list'
          element={
            <SuspensedView>
              <VendorList />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/vendor-list/propertise-service-list'
          element={
            <SuspensedView>
              <PropertiseServiceList />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/vendor-list/request-custom-list'
          element={
            <SuspensedView>
              <RequestCustomList />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/scope-detail/:id'
          element={
            <SuspensedView>
              <ScopeDetails />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/create-request-service'
          element={
            <SuspensedView>
              <CreateServiceRequest />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/create-request-service/:id'
          element={
            <SuspensedView>
              <CreateServiceRequest />
            </SuspensedView>
          }
        />
        {/* <Route
          path='/request-management/service/create-request-service-internal/:flg'
          element={
            <SuspensedView>
              <CreateService />
            </SuspensedView>
          }
        /> */}
        <Route
          path='/request-management/service/create-request-service-main'
          element={
            <SuspensedView>
              <CreateServiceMain />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/review-request-service'
          element={
            <SuspensedView>
              <ReviewRequest />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/create-vendor-service'
          element={
            <SuspensedView>
              <CreateVendorList />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/handle-tenant-request-internal/:id'
          element={
            <SuspensedView>
              <HandleTenantInternal />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/handle-tenant-request-external/:id'
          element={
            <SuspensedView>
              <HandleTenantExternal />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/handle-tenant-request/:id/select-list'
          element={
            <SuspensedView>
              <ServiceListTenant />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/handle-tenant-request/:id/custom-list'
          element={
            <SuspensedView>
              <CustomListTenant />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/handle-tenant-request/:id/internal-team'
          element={
            <SuspensedView>
              <InternalTeamTenant />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/handle-tenant-request/:id/service-provider-list'
          element={
            <SuspensedView>
              <ServiceProviderListTenant />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/edit-request/:id'
          element={
            <SuspensedView>
              <HandleDraft />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/edit-request/:id/internal-team'
          element={
            <SuspensedView>
              <InternalTeamDraft />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/edit-request/:id/service-provider-list'
          element={
            <SuspensedView>
              <ServiceProviderDraft />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/edit-request/:id/select-list'
          element={
            <SuspensedView>
              <ServiceListDraft />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/edit-request/:id/select-property'
          element={
            <SuspensedView>
              <SelectPropertyDraft />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/edit-request/:id/custom-list'
          element={
            <SuspensedView>
              <CustomListDraft />
            </SuspensedView>
          }
        />
        <Route
          path='/request-management/service/tenant-request-details/:id'
          element={
            <SuspensedView>
              <ViewTenantRequest />
            </SuspensedView>
          }
        />
        <Route
          path='/maintenance'
          element={
            <SuspensedView>
              <Maintenance />
            </SuspensedView>
          }
        />
        <Route
          path='/maintenance/create-request/:type/:flg/:jobType'
          element={
            <SuspensedView>
              <CreateRequest />
            </SuspensedView>
          }
        />
        <Route
          path='/maintenance/create-request/:type/:flg/:jobType/:id'
          element={
            <SuspensedView>
              <CreateRequest />
            </SuspensedView>
          }
        />
        <Route
          path='/maintenance/create-request/purchase-order/:type/:flg/:id'
          element={
            <SuspensedView>
              <POTemplate />
            </SuspensedView>
          }
        />
        <Route
          path='/maintenance/create-request/purchase-order/:type/:flg/:id/:poType'
          element={
            <SuspensedView>
              <POTemplate />
            </SuspensedView>
          }
        />
        <Route
          path='/maintenance/view-purchase-order/:type/:flg/:id/:poId'
          element={
            <SuspensedView>
              <ViewPOTemplate />
            </SuspensedView>
          }
        />
        <Route
          path='/maintenance/create-request/purchase-order/:type/:flg/:id/:poId'
          element={
            <SuspensedView>
              <POTemplate />
            </SuspensedView>
          }
        />
        <Route
          path='/maintenance/create-request/purchase-order/:type/:flg/:id/:poId/:poType'
          element={
            <SuspensedView>
              <POTemplate />
            </SuspensedView>
          }
        />
        <Route
          path='/maintenance/request/:type/:id'
          element={
            <SuspensedView>
              <ViewEditRequest />
            </SuspensedView>
          }
        />
        <Route
          path='/maintenance/request/:reqId/job/:id'
          element={
            <SuspensedView>
              <ServiceJob />
            </SuspensedView>
          }
        />

        <Route
          path='/maintenance-resident-request/:id'
          element={
            <SuspensedView>
              <ViewResidentRequestOfMaintenance />
            </SuspensedView>
          }
        />

        {/* Properties */}
        <Route
          path='/properties'
          element={
            <SuspensedView>
              <Properties />
            </SuspensedView>
          }
        />

        {/* Properties Building Details */}
        <Route
          path='/properties/building-details/:id'
          element={
            <SuspensedView>
              <BuildingDetailList />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/building-details/:id/floors/:id'
          element={
            <SuspensedView>
              <FloorDetailList type={'building'} />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/building-details/:id/floors/:id/edit-property/:propertyId'
          element={
            <SuspensedView>
              <EditProperty />
            </SuspensedView>
          }
        />

        <Route
          path='/properties/building-details/:id/floors/:id/add-property'
          element={
            <SuspensedView>
              <AddFloorProperty type='floor' />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/building-details/:id/add-property'
          element={
            <SuspensedView>
              <AddFloorProperty type='building' />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/building-details/:id/edit-property/:propertyId'
          element={
            <SuspensedView>
              <EditProperty />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/add-property'
          element={
            <SuspensedView>
              <AddGlobalProperty />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/edit-property/:id'
          element={
            <SuspensedView>
              <AddGlobalProperty />
            </SuspensedView>
          }
        />

        {/* Community detail  */}
        <Route
          path='/properties/community-details/:id'
          element={
            <SuspensedView>
              <CommunityDetailList />
            </SuspensedView>
          }
        />

        <Route
          path='/properties/community-details/:id/add-property'
          element={
            <SuspensedView>
              <AddCommunityProperty type='community' />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/community-details/:id/edit-property/:propertyId'
          element={
            <SuspensedView>
              <EditProperty />
            </SuspensedView>
          }
        />

        <Route
          path='/properties/community-details/:id/unit-cluster/:id/add-property'
          element={
            <SuspensedView>
              <AddCommunityProperty type='community' isFromUnitCluster={true} />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/community-details/:id/building-cluster/:id/add-property'
          element={
            <SuspensedView>
              <AddCommunityProperty
                type='community'
                isFromUnitCluster={false}
                isFromBuildingCluster={true}
              />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/community-details/:id/mixed-cluster/:id/add-property'
          element={
            <SuspensedView>
              <AddCommunityProperty
                type='community'
                isFromUnitCluster={false}
                isFromBuildingCluster={false}
                isFromMixedCluster={true}
              />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/community-details/:id/mixed-cluster/:id/group/:id/add-property'
          element={
            <SuspensedView>
              <AddCommunityProperty
                type='community'
                isFromUnitCluster={false}
                isFromBuildingCluster={false}
                isFromMixedCluster={true}
              />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/community-details/:id/mixed-cluster/:id/group/:id/floor/:id/add-property'
          element={
            <SuspensedView>
              <AddCommunityProperty
                isGroupFloor={true}
                type='community'
                isFromUnitCluster={false}
                isFromBuildingCluster={false}
                isFromMixedCluster={true}
              />
            </SuspensedView>
          }
        />

        <Route
          path='/properties/community-details/:id/building-cluster/:id/building/:id/add-property'
          element={
            <SuspensedView>
              <AddFloorProperty type='buildingClusterBuilding' />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/community-details/:id/building-cluster/:id/building/:id/floor/:id/add-property'
          element={
            <SuspensedView>
              <AddFloorProperty type='buildingClusterBuildingFloor' />
            </SuspensedView>
          }
        />

        <Route
          path='/properties/community-details/:id/unit-cluster/:id'
          element={
            <SuspensedView>
              <UnitBuildingClusterDetailList type={'unitCluster'} />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/community-details/:id/unit-cluster/:id/edit-property/:propertyId'
          element={
            <SuspensedView>
              <EditProperty />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/community-details/:id/building-cluster/:id/edit-property/:propertyId'
          element={
            <SuspensedView>
              <EditProperty />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/community-details/:id/mixed-cluster/:id/edit-property/:propertyId'
          element={
            <SuspensedView>
              <EditProperty />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/community-details/:id/building-cluster/:id'
          element={
            <SuspensedView>
              <UnitBuildingClusterDetailList type={'buildingCluster'} />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/community-details/:id/mixed-cluster/:id'
          element={
            <SuspensedView>
              <UnitBuildingClusterDetailList type={'mixedCluster'} />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/community-details/:id/mixed-cluster/:id/group/:id'
          element={
            <SuspensedView>
              <GroupDetailList type={'mixedCluster'} />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/community-details/:id/mixed-cluster/:id/group/:id/edit-property/:propertyId'
          element={
            <SuspensedView>
              <EditProperty />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/community-details/:id/mixed-cluster/:id/group/:id/floor/:id'
          element={
            <SuspensedView>
              <CommunityGroupFloorDetailList />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/community-details/:id/mixed-cluster/:id/group/:id/floor/:id/edit-property/:propertyId'
          element={
            <SuspensedView>
              <EditProperty />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/community-details/:id/building-cluster/:id/building/:id'
          element={
            <SuspensedView>
              <CommunityBuildingDetailList />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/community-details/:id/building-cluster/:id/building/:id/edit-property/:propertyId'
          element={
            <SuspensedView>
              <EditProperty />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/community-details/:id/building-cluster/:id/building/:id/floor/:id'
          element={
            <SuspensedView>
              <CommunityBuildingFloorDetailList />
            </SuspensedView>
          }
        />
        <Route
          path='/properties/community-details/:id/building-cluster/:id/building/:id/floor/:id/edit-property/:propertyId'
          element={
            <SuspensedView>
              <EditProperty />
            </SuspensedView>
          }
        />
        {/* <Route
          path='/properties/community-details/building-cluster/:id/building/:id/floor/:id'
          element={
            <SuspensedView>
              <FloorDetailList type={'community'}/>
            </SuspensedView>
          }
        /> */}
        <Route
          path='/properties/community-details/:id/building-cluster/:id/building/:id/floor/:id'
          element={
            <SuspensedView>
              <BuildingFloorDetailList />
            </SuspensedView>
          }
        />

        {/* development */}

        <Route
          path='/properties/add-development'
          element={
            <SuspensedView>
              <AddPropertyDevelopment />
            </SuspensedView>
          }
        />
        {/* <Route
          path='/properties/edit-development'
          element={
            <SuspensedView>
              <AddPropertyDevelopment />
            </SuspensedView>
          }
        /> */}
        <Route
          path='/properties/edit-development'
          element={
            <SuspensedView>
              <EditPropertyDevelopment />
            </SuspensedView>
          }
        />

        <Route
          path='/properties/community-details/:id/building-cluster/:id/building/:id/edit-building'
          element={
            <SuspensedView>
              <EditBuilding />
            </SuspensedView>
          }
        />

        <Route
          path='/properties/community-details/:id/mixed-cluster/:id/group/:id/edit-building'
          element={
            <SuspensedView>
              <EditBuilding />
            </SuspensedView>
          }
        />
        <Route
          path='/property-profile/:id'
          element={
            <SuspensedView>
              <PropertyPrpfile />
            </SuspensedView>
          }
        />

        <Route
          path='/tenants-new'
          element={
            <SuspensedView>
              <TenantsNew />
            </SuspensedView>
          }
        />

        <Route
          path='/tenant-profile/:od'
          element={
            <SuspensedView>
              <ViewTenantNew />
            </SuspensedView>
          }
        />

        {/* CRM  */}
        <Route
          path='/crm-residents'
          element={
            <SuspensedView>
              <CRMResidents />
            </SuspensedView>
          }
        />

        <Route
          path='/resident-request/:id'
          element={
            <SuspensedView>
              <ViewResidentRequest />
            </SuspensedView>
          }
        />

        <Route
          path='/lease-resident-request/:id'
          element={
            <SuspensedView>
              <ViewResidentRequestOfLeases />
            </SuspensedView>
          }
        />

        <Route
          path='/finance-income'
          element={
            <SuspensedView>
              <Incomes />
            </SuspensedView>
          }
        />

        <Route
          path='/finance-income/view-income-payment/:id'
          element={
            <SuspensedView>
              <ViewEditIncome />
            </SuspensedView>
          }
        />

        <Route
          path='settings/ticket-assignment'
          element={
            <SuspensedView>
              <TicketAssignment />
            </SuspensedView>
          }
        />
        <Route
          path='settings/ticket-assignment/add-rule'
          element={
            <SuspensedView>
              <AddAssignmentRule />
            </SuspensedView>
          }
        />
        <Route
          path='settings/tenant-request'
          element={
            <SuspensedView>
              <TenantRequest />
            </SuspensedView>
          }
        />
        <Route
          path='settings/tenant-request/:mainId/sub-category'
          element={
            <SuspensedView>
              <TenantSubRequest />
            </SuspensedView>
          }
        />

        {/* Owners */}
        <Route
          path='/owners'
          element={
            <SuspensedView>
              <Owners />
            </SuspensedView>
          }
        />

        <Route
          path='/owner-profile/:id'
          element={
            <SuspensedView>
              <ViewOwner />
            </SuspensedView>
          }
        />

        {/* Income Payments -  New Flow (like expenses) */}
        <Route
          path='/financials/add-income-payment/:flg'
          element={
            <SuspensedView>
              <AddIncomePayment />
            </SuspensedView>
          }
        />
        <Route
          path='/financials/add-income-payment/:flg/:id'
          element={
            <SuspensedView>
              <AddIncomePayment />
            </SuspensedView>
          }
        />

        <Route
          path='/financials/add-invoice/:flg'
          element={
            <SuspensedView>
              <GenerateInvoice />
            </SuspensedView>
          }
        />

        <Route
          path='/financials/add-invoice/:flg/:id'
          element={
            <SuspensedView>
              <GenerateInvoice />
            </SuspensedView>
          }
        />

        <Route
          path='/finance-income/add-invoice/:flg'
          element={
            <SuspensedView>
              <AddInvoice />
            </SuspensedView>
          }
        />

        <Route
          path='/finance-income/add-receipt/:flg'
          element={
            <SuspensedView>
              <RaiseReceipt />
            </SuspensedView>
          }
        />

        <Route
          path='/finance-income/add-receipt/:flg/:id'
          element={
            <SuspensedView>
              <RaiseReceipt />
            </SuspensedView>
          }
        />

        <Route
          path='/finance-income/view-receipt/:id'
          element={
            <SuspensedView>
              <ViewReceipt />
            </SuspensedView>
          }
        />

        <Route
          path='/finance-income/edit-receipt/:id'
          element={
            <SuspensedView>
              <EditReceipt />
            </SuspensedView>
          }
        />

        <Route
          path='/finance-income/add-invoice/:flg/:id'
          element={
            <SuspensedView>
              <AddInvoice />
            </SuspensedView>
          }
        />

        <Route
          path='/finance-income/edit-invoice/:flg/:id'
          element={
            <SuspensedView>
              <EditInvoice />
            </SuspensedView>
          }
        />

        <Route
          path='/finance-income/view-invoice/:id'
          element={
            <SuspensedView>
              <ViewInvoice />
            </SuspensedView>
          }
        />

        {/* Credit Note */}
        <Route
          path='/finance-income/issue-credit-note/:flg'
          element={
            <SuspensedView>
              <IssueCreditNote />
            </SuspensedView>
          }
        />

        <Route
          path='/finance-income/issue-credit-note/:flg/:id'
          element={
            <SuspensedView>
              <IssueCreditNote />
            </SuspensedView>
          }
        />

        <Route
          path='/finance-income/view-credit-note/:id'
          element={
            <SuspensedView>
              <ViewCreditNote />
            </SuspensedView>
          }
        />

        <Route
          path='/finance-income/edit-credit-note/:flg/:id'
          element={
            <SuspensedView>
              <EditCreditNote />
            </SuspensedView>
          }
        />

        <Route
          path='/finance-income/issue-debit-note/:flg'
          element={
            <SuspensedView>
              <IssueDebitNote />
            </SuspensedView>
          }
        />

        <Route
          path='/finance-income/issue-debit-note/:flg/:id'
          element={
            <SuspensedView>
              <IssueDebitNote />
            </SuspensedView>
          }
        />
        <Route
          path='/finance-income/view-debit-note/:id'
          element={
            <SuspensedView>
              <ViewDebitNote />
            </SuspensedView>
          }
        />
        <Route
          path='/finance-income/edit-debit-note/:flg/:id'
          element={
            <SuspensedView>
              <EditDebitNote />
            </SuspensedView>
          }
        />

        <Route
          path='/finance-income/share/:type/:id'
          element={
            <SuspensedView>
              <ShareNote />
            </SuspensedView>
          }
        />

        <Route
          path='settings/late-payment-fee'
          element={
            <SuspensedView>
              <LatePaymentFeeListing />
            </SuspensedView>
          }
        />

        <Route
          path='settings/communications'
          element={
            <SuspensedView>
              <Communications />
            </SuspensedView>
          }
        />

        <Route
          path='settings/communications/:type'
          element={
            <SuspensedView>
              <TypeListing />
            </SuspensedView>
          }
        />

        <Route
          path='settings/communications/:type/email-templates/select'
          element={
            <SuspensedView>
              <SelectTemplates />
            </SuspensedView>
          }
        />

        <Route
          path='settings/residential-leasing'
          element={
            <SuspensedView>
              <ResidentialLeasing />
            </SuspensedView>
          }
        />

        <Route
          path='settings/residential-leasing/annual-leases'
          element={
            <SuspensedView>
              <AnnualLeases />
            </SuspensedView>
          }
        />
         <Route
          path='settings/residential-leasing/annual-leases/:type/:id'
          element={
            <SuspensedView>
              <Configurations />
            </SuspensedView>
          }
        />
         <Route
          path='settings/residential-leasing/annual-leases/:type/:id/add-configuration/:flg'
          element={
            <SuspensedView>
              <AddConfiguration />
            </SuspensedView>
          }
        />

        <Route
          path='settings/residential-leasing/annual-leases/:type/:id/add-configuration/:flg/:configId'
          element={
            <SuspensedView>
              <AddConfiguration />
            </SuspensedView>
          }
        />

        <Route
          path='settings/residential-leasing/annual-leases/:type/:id/add-booking-fee-configuration/:flg'
          element={
            <SuspensedView>
              <AddBookingFeeConfiguration />
            </SuspensedView>
          }
        />

        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
