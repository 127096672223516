import React, {useEffect, useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import add from '../../../../../../../../img/add.png'
import {ApiPost} from '../../../../../../../../apiCommon/helpers/API/ApiData'
import {MultiSelect} from 'react-multi-select-component'
import ReactPaginate from 'react-paginate'
import AddCharge from './AddCharge'

const LeaseCard: React.FC<{
  name: string
  description: string
  path: string
  configureCount: any
  navigate: (path: string) => void
}> = ({name, description, path, navigate, configureCount}) => (
  <Col md={12} className='mb-4'>
    <div
      className='card card-flush gap-5 p-3 cursor-pointerlight-gry-dark-shadow cursor-pointer lease-setting-table-card'
      // style={{boxShadow: '0 5px 8px rgba(0, 0, 0, 0.1), 0 0px 5px rgba(0, 0, 0, 0.08)'}}
      onClick={() => {
        if (path) navigate(path)
      }}
    >
      <div className='card-body p-3'>
        <div className='d-flex align-items-center'>
          <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4 mb-1'>{name}</p>
          <p className='fs-4 fnt-500 labl-gry mb-1 light-dark-theme-color ms-auto'>
            Configured{' '}
            <span className='ms-3'>
              <b>{configureCount}</b>
            </span>
          </p>
        </div>
        <p className='fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'>{description}</p>
      </div>
    </div>
  </Col>
)

const LeaseCharges: React.FC = () => {
  const [leaseTypes, setLeaseType] = useState<any>([
    // {
    //   name: 'Booking Fees',
    //   description:
    //     'Allows you to set the booking fee on properties that are being booked for leases and charge them directly to the prospect tenant.',
    //   path: '',
    //   configureCount: 1,
    // },
    // {
    //   name: 'Rent',
    //   description:
    //     'Allows you to apply rent charges on your leases and charge them directly to your tenant.',
    //   path: '',
    //   configureCount: 1,
    // },
    // {
    //   name: 'Contract Fees',
    //   description:
    //     'Allows you to apply contract-related fees on your leases and charge them directly to your tenant.',
    //   path: '/settings/residential-leasing/annual-leases/contract-fee',
    //   configureCount: 1,
    // },
    // {
    //   name: 'Admin Fees',
    //   description:
    //     'Allows you to apply admin-related fees on your leases and charge them directly to your tenant.',
    //   path: '/settings/residential-leasing/annual-leases/admin-fee',
    //   configureCount: 1,
    // },
    // {
    //   name: 'Management Fees',
    //   description:
    //     'Allows you to apply property management-related fees on your leases and charge them directly to your tenant.',
    //   path: '/settings/residential-leasing/annual-leases/management-fee',
    //   configureCount: 1,
    // },
    // {
    //   name: 'Maintenance Fees',
    //   description:
    //     'Allows you to apply maintenance-related fees for work conducted on the property during a lease and charge them directly to your tenant.',
    //   path: '/settings/residential-leasing/annual-leases/maintenance-fee',
    //   configureCount: 1,
    // },
    // {
    //   name: 'Housekeeping Fees',
    //   description:
    //     'Allows you to apply fees related to cleaning and housekeeping services provided to your tenant in the property they are leasing and charge them directly to your tenant.',
    //   path: '/settings/residential-leasing/annual-leases/housekeeping-fee',
    //   configureCount: 1,
    // }
  ])

  const predefinedPaths: Record<string, string> = {
    'Contract Fees': '/settings/residential-leasing/annual-leases/contract-fee',
    'Admin Fees': '/settings/residential-leasing/annual-leases/admin-fee',
    'Management Fees': '/settings/residential-leasing/annual-leases/management-fee',
    'Maintenance Fees': '/settings/residential-leasing/annual-leases/maintenance-fee',
    'Housekeeping Fees': '/settings/residential-leasing/annual-leases/housekeeping-fee',
  }

  const bookingFeePath: Record<string, string> = {
    'Booking Fees': '/settings/residential-leasing/annual-leases/booking-fee',
  }

  const rentPath: Record<string, string> = {
    'Rent': '',
  }

  const getLeaseChargesFilters: any = localStorage.getItem('leaseChargesFilters')
  const leaseChargesFilters = JSON.parse(getLeaseChargesFilters)

  const navigate = useNavigate()
  const [searchChargeName, setSearchChargeName] = useState<any>(leaseChargesFilters != null ? leaseChargesFilters?.searchChargeName : '')
  const [sortType, setSortType] = useState<any>(leaseChargesFilters != null ? leaseChargesFilters?.sortType : [])

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const [page, setPage] = useState<any>(1)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [limit, setLimit] = useState<any>(20)
  const [dataCount, setDataCount] = useState<any>(0)

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const [sortOptions, setSortOptions] = useState<any>([
    {value: 'A-Z', label: 'Alphabetic (A-Z)'},
    {value: 'Z-A', label: 'Alphabetic (Z-A)'},
    {value: 'latest', label: '(Latest - Oldest)'},
    {value: 'oldest', label: '(Oldest - Latest)'},
  ])

  const [showAddCharge, setShowAddCharge] = useState<any>(false)

  const getChargesList = async () => {
    const body = {
      limit,
      page,
      searchName: searchChargeName,
      sortType: sortType?.length === 0 ? 'A-Z' : sortType?.[0]?.value, // oldest, A-Z, Z-A
    }

    try {
      const response = await ApiPost('corporate/lease_fee_list/get', body)
      const updatedLeaseTypes = response?.data?.data?.data?.map((item: any) => ({
        ...item,
        path: predefinedPaths[item.name] ? (predefinedPaths[item.name]+'/'+item?._id) 
              : bookingFeePath[item.name] ? (bookingFeePath[item.name]+'/'+item?._id) 
              : rentPath[item.name] ? (rentPath[item.name]+'/'+item?._id) 
              : '/settings/residential-leasing/annual-leases/'+item.name+'/'+item?._id , // Assign path if it matches, else keep it empty
      }))
      setLeaseType(updatedLeaseTypes)

      setPageLimit(response?.data?.data?.state?.page_limit)
      setDataCount(response?.data?.data?.state?.data_count)
    } catch (error:any) {
      console.error('Failed to fetch lease types:', error)
      if (error?.message === 'Your account has been suspended!') {
        window.location.pathname = '/auth'
        localStorage.clear()
      }
    }
  }

  useEffect(() => {
    getChargesList()

    const chargesFilter : any = {
      searchChargeName: searchChargeName,
      sortType: sortType

    }
    localStorage.setItem('leaseChargesFilters', JSON.stringify(chargesFilter))
  }, [page, searchChargeName, sortType])


  return (
    <div id='' className='pt-0 mt-10 px-2 ps-1'>
      <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3' style={{minHeight: '75vh'}}>
        <div className='card-body pt-0 mt-5 py-0 px-3'>
          <Row>
            <Col>
              <p className='labl-gry fs-4 mb-0 light-dark-theme-color'>
                Configure the charges that you would like to automatically include in your leases.
                These will automatically be charged directly to your tenant once a booking or a
                lease is created. You can customize each lease charge based on your property
                settings and ensure your leases reflect the required charges without having to add
                them manually.
              </p>
            </Col>
          </Row>

          <div className='d-flex align-items-center py-5 pt-2 gy-3 mx-0 my-6 xyz tst'>
            <label className='head-text me-5 pe-3' style={{fontWeight: '600'}}>
              Search{' '}
            </label>

            {/* Search by Charge Name */}
            <div className='me-4'>
              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Charge Name...'
                  style={inputBaseStyle}
                  inputProps={{'aria-label': 'search'}}
                  value={searchChargeName}
                  onChange={(e: any) => setSearchChargeName(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='search'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>

             {/* Sort Type */}
            <div className='me-4 test jobs'>
              <div className='property-test d-none-multi-checbox'>
                <div
                  className={`multi-select-container jobs-property ms-0 me-5 sort-type ${
                    sortType?.length === 0 ? 'no-value select ' : 'has-value'
                  }`}
                >
                  <MultiSelect
                    options={sortOptions}
                    value={sortType}
                    onChange={(e: any) => {
                      let newVal: any = e
                      if (e?.length > 1) newVal = e.slice(1)
                      setSortType(newVal)
                    }}
                    labelledBy=''
                    overrideStrings={{
                      allItemsAreSelected: '',
                    }}
                    hasSelectAll={false}
                    disableSearch
                    closeOnChangedValue={true}
                  />
                </div>
              </div>
            </div>

            <button
              type='button'
              className='btn btn-sm fw-bold green-hollow-btn px-4 justify-content-center btn-fit-content ms-auto'
              onClick={() => setShowAddCharge(true)}
            >
              <img src={add} height={16} width={16} className='me-4' />
              Add Charge
            </button>
          </div>

          <Row className='mb-5'>
            {leaseTypes.map((lease: any) => (
              <LeaseCard
                key={lease.name}
                name={lease.name}
                description={lease.description}
                path={lease.path}
                configureCount={lease.configureCount}
                navigate={navigate} // Pass the navigate function as a prop
              />
            ))}
          </Row>

          {dataCount > 20 && (
            <div className='row mb-5'>
              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
              <div className='row mt-5'>
                <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                  <div
                    className='dataTables_paginate paging_simple_numbers'
                    id='kt_ecommerce_sales_table_paginate'
                  >
                    <ReactPaginate
                      breakLabel='...'
                      nextLabel='>'
                      pageClassName='paginate_button page-item +'
                      pageLinkClassName='page-link'
                      containerClassName='pagination'
                      activeClassName='active'
                      previousClassName='paginate_button page-item previous'
                      nextClassName='paginate_button page-item next'
                      previousLinkClassName='page-link'
                      nextLinkClassName='page-link'
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageLimit}
                      forcePage={page - 1}
                      previousLabel='<'
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <AddCharge show={showAddCharge} handleClose={()=>setShowAddCharge(false)} updateChargesList={()=>{getChargesList()
        setShowAddCharge(false)
      }} />
    </div>
  )
}

export default LeaseCharges
