import React from 'react'
import {Controller} from 'react-hook-form'
import {useFormContext} from 'react-hook-form'
import {MultiSelect} from 'react-multi-select-component'
import { CustomValueRenderer } from '../../../../Utilities/utils';

interface MultiSelectInputProps {
  name:string,
  labelContainerHeight? : string,
  wrapperStyle?: string
  subLabel?: string
  label?: string
  chargeOptions: any[] // Options for the multiselect
  selectedCharge: any[] // Currently selected options
  setSelectedCharge: (value: any[]) => void // Function to update selected options
  required?: boolean,
  disableSearch?: boolean
  isMultiSelect?: boolean
  disableInput?: boolean
  showRemove?: boolean
}

const MultiSelectInput: React.FC<MultiSelectInputProps> = ({
  name,
  wrapperStyle = '',
  subLabel = '',
  label = '',
  chargeOptions,
  selectedCharge,
  setSelectedCharge,
  required,
  disableSearch = false,
  isMultiSelect = false,
  labelContainerHeight = 'auto',
  disableInput = false,
  showRemove = true

}) => {
  const {
    control,
    setValue,
    formState: {errors},
  } = useFormContext()

  const handleChange = (e: any) => {
    let newVal: any = e;
   
    if(!isMultiSelect){
    if (e?.length > 1) newVal = e.slice(1);
    }

    if(name == 'invoicingType' && e?.length == 0)
    newVal = selectedCharge

    setSelectedCharge(newVal);

    if(required){
      if (newVal?.length === 0) {
        setValue(`${name}`, '', { shouldValidate: true });
      } else {
        setValue(`${name}`, newVal, { shouldValidate: true });
      }
  }

  };

  return (
    <div className={`${wrapperStyle}`}>
      {label && <div className='percentage-label-container' style={{height:labelContainerHeight}}>
        <label className={`percentage-input-label white-dark-theme-color ${(required || name == 'invoicingType') ? 'required' : ''}`}>{label}</label>
        {subLabel && <p className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2'>{subLabel}</p>}
      </div>}

      <div className={`d-flex justify-content-start ${!showRemove ? 'd-none-clear-button' : ''}`}>
        <span className='multiselect-container jobs property test'>
          <div className={`lease-multiselect-dropdown ${!isMultiSelect ? 'd-none-multi-checbox' : ''}`}>
            <div
              className={`multi-select-container ms-0 me-5 ${
                selectedCharge.length === 0 ? 'no-value select' : 'has-value'
              } ${wrapperStyle}`}
            >
              <Controller
                name={`${name}`}
                control={control}
                rules={{required: required}}
                render={({field}) => (
                  <MultiSelect
                    {...field}
                    disabled={disableInput}
                    options={chargeOptions.map((option:any) => ({
                      ...option,
                      disabled: isMultiSelect 
                        ? (option.label === 'Total Rent Value' &&
                          selectedCharge.some((item: any) => item.label === 'Single Rent Installment')) ||
                          (option.label === 'Single Rent Installment' &&
                          selectedCharge.some((item: any) => item.label === 'Total Rent Value'))
                        :  undefined,
                    }))}
                    value={selectedCharge}
                    onChange={handleChange}
                    labelledBy=''
                    overrideStrings={{ allItemsAreSelected: `${selectedCharge?.[0]?.label}` }}
                    hasSelectAll={false}
                    closeOnChangedValue={!isMultiSelect ? true : false}
                    disableSearch={disableSearch}
                    valueRenderer= {isMultiSelect ? CustomValueRenderer : undefined}
                  />
                )}
              />
              {errors[name] && (
                <p
                  style={{
                    color: 'var(--kt-danger)',
                    gridColumn: '2/2',
                  }}
                  className='m-0 mt-1 fnt-400'
                >
                  This field is required
                </p>
              )}
            </div>
          </div>
        </span>
      </div>
    </div>
  )
}

export default MultiSelectInput
