import React, {useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import backArrow from '../../../../../../img/back-arrow.png'
import LeaseCharges from './SubTabs/LeaseCharges/LeaseCharges'

const tabs = [
  'Lease Charges',
  'Deposits',
  'Late Payment Fees',
  'Bookings',
  'New Leases',
  'Renewals',
  'Terminations',
  'Properties',
  'Approval Workflow',
]

const AnnualLeases: React.FC = () => {
  const navigate = useNavigate()
  const [tab, setTab] = useState<string>('Lease Charges')

  return (
    <div className='pt-0 mt-0 px-2 ps-1'>
      <div className='d-flex align-items-center gap-2 gap-lg-3'>
        <div className='d-flex align-items-center gap-3'>
          <span
            className='rounded-circle cursor-pointer'
            onClick={() => navigate('/settings/residential-leasing')}
          >
            <img src={backArrow} alt='Back' height='14px' width='14px' />
          </span>
          <h2 className='page-heading m-0 head-text'>Annual Leases - Residential</h2>
        </div>
      </div>

      <div className='row mt-5 mx-0'>
        <div className='col-12'>
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 xyz'>
            {tabs.map((tabName) => (
              <li key={tabName} className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-0 me-5 fs-4 ${
                    tab === tabName ? 'active' : 'fnt-400'
                  }`}
                  onClick={() => setTab(tabName)}
                >
                  {tabName}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {tab == 'Lease Charges' ? <LeaseCharges /> : <></>}

      {/* <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3' style={{minHeight: '75vh'}}>
        <div className='card-body pt-0 mt-5 py-0 px-3'></div>
      </div> */}
    </div>
  )
}

export default AnnualLeases
