import React from 'react'
import {Modal, Button, Form} from 'react-bootstrap'
import switchOffIcon from '../../../../../../../../../img/switch-off.png'
import switchOnIcon from '../../../../../../../../../img/switch-on.png'

interface ActivityStatusModalI {
  show: boolean
  onHide: any
  onAdd: any
  selectedConfig: any
}

const ActivityStatusModal = ({show, onHide, onAdd, selectedConfig}: ActivityStatusModalI) => {
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-450px mx-auto'
      show={show}
      onHide={() => {
        onHide()
      }}
      backdrop={true}
    >
      <div className='modal-body p-0 modal-body-without-header'>
        <div
          className='row px-6 py-5 m-0'
          style={{position: 'sticky', top: 0, background: 'transparent', zIndex: 2}}
        >
          <div className={`col-12 px-0`}>
            <div className='d-flex justify-content-end'>
              <label
                className='head-text fs-5 cursor-pointer blue-dark-text fnt-700'
                onClick={() => {
                  onHide()
                }}
              >
                X
              </label>
            </div>
          </div>

          <div className='row d-flex flex-column align-items-center'>
            <div className='d-flex justify-content-center'>
              <h2 className='text-dark white-dark-theme-color mb-3'>
                <b>{`${selectedConfig?.activityStatus ? 'Deactivate' : 'Activate'} Charge`}</b>
              </h2>
            </div>
            <div className='d-flex justify-content-center mt-3'>
              <div className='d-flex align-items-center justify-content-center'>
                <img
                  src={!selectedConfig?.activityStatus ? switchOnIcon : switchOffIcon}
                  height={25}
                  width={25}
                />
                <p className='m-0 ms-3'>{selectedConfig?.activityStatus ? 'Deactive' : 'Active'}</p>
              </div>
            </div>

            <div>
              <p className='my-3 text-center text-dark white-dark-theme-color fs-3 fnt-400 mb-4'>
                You are about to {selectedConfig?.activityStatus ? 'deactivate' : 'activate'} this
                charge.
              </p>

              <p className='my-3 text-center text-dark white-dark-theme-color fs-3 fnt-400 mb-4'>
                Once {selectedConfig?.activityStatus ? 'deactivated' : 'activated'}, the system will{' '}
                {selectedConfig?.activityStatus ? 'stop' : 'start'} auto-generating this charge on
                bookings and leases.
              </p>

              <p className='my-3 text-center text-dark white-dark-theme-color fs-3 fnt-400 mb-4'>
                Are you sure you want to continue?
              </p>
            </div>
            <div className='col-12 my-4'>
              {!selectedConfig?.activityStatus ? (
                <button
                  type='button'
                  className='btn btn-sm fw-bold select-btn px-2 mx-auto justify-content-center status-w-175'
                  onClick={() => {
                    onAdd()
                  }}
                >
                  Enable
                </button>
              ) : (
                <button
                  type='button'
                  className='btn btn-sm fw-bold px-2 mx-auto justify-content-center status-w-175 d-flex align-items-center justify-content-center'
                  style={{
                    backgroundColor: '#a6a6a6',
                    border: 'none',
                    color: 'white',
                    fontSize: '11px',
                    height: '31px',
                  }}
                  onClick={() => {
                    onAdd()
                  }}
                >
                  Disable
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ActivityStatusModal
