import React, {useState, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import {Col, Row} from 'react-bootstrap'
import {getUnitTypeName} from '../../../../../../../../../Utilities/utils'

interface SelectUnitsProps {
  show: any
  handleClose: any
  updateUnits: (val: any) => void
  unitsData: any
}

const SelectUnits = ({handleClose, show, updateUnits, unitsData}: SelectUnitsProps) => {
  const [checkedValues, setCheckedValues] = useState<any>([])

  const [furnishedValues, setFurnishedValues] = useState<any>('')
  const [unitTypeValues, setUnitTypeValues] = useState<any>('')
  const [bedroomsValues, setBedroomsValues] = useState<any>('')

  const [devUnits, setDevUnits] = useState<any>([])

  const handleCheckboxChange = (index: number, isChecked: boolean) => {
    const updatedDev: any = {...unitsData}
    const updatedValues = [...unitsData?.units]

    updatedValues[index].isSelected = isChecked
    updatedDev.units = updatedValues
    setDevUnits(updatedValues)
    updateUnits(updatedDev)
  }

  const selectAll = (select: boolean) => {
    const updatedValues = Array(unitsData.length).fill(select)
    setCheckedValues(updatedValues)
    updateUnits(updatedValues)
  }

  const furnishingTypeMap: {[key: number]: string} = {
    0: 'Unfurnished',
    1: 'Furnished',
  }

  const unitTypeMap: {[key: number]: string} = {
    0: 'Apartment',
    1: 'Penthouse',
    2: 'Common Area',
    3: 'Townhouse',
    4: 'Villa',
    5: 'Other',
    6: 'Office',
    7: 'Restaurant',
    8: 'Cafe',
    9: 'Retail',
  }

  const bedroomsMap: {[key: number]: string} = {
    1: '1 BR',
    2: '2 BR',
    3: '3 BR',
    4: '4 BR',
    5: '5 BR',
    6: '6 BR',
    7: '7 BR',
    8: '8 BR',
    9: '9 BR',
    0: '10 BR',
  }

  const extractAndMap = (
    data: any[],
    key:
      | keyof typeof furnishingTypeMap
      | keyof typeof unitTypeMap
      | keyof typeof bedroomsMap
      | string,
    map: {[key: number]: string}
  ): string => {
    const uniqueValues = Array.from(
      new Set(data?.map((item: any) => parseInt(item[key], 10)))
    ).sort((a, b) => a - b)

    return uniqueValues
      .map((value) => map[value] || '') // Use the map object to convert values to labels
      .filter((label) => label) // Remove any undefined or empty labels
      .join(', ')
  }

  useEffect(() => {
    if (!unitsData?.units) return

    setFurnishedValues(extractAndMap(unitsData.units, 'furnishingType', furnishingTypeMap))
    setUnitTypeValues(extractAndMap(unitsData.units, 'unitType', unitTypeMap))
    setBedroomsValues(extractAndMap(unitsData.units, 'bedrooms', bedroomsMap))

    setDevUnits(unitsData?.units)
  }, [unitsData])

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <Row>
          <div
            className='card'
            style={{
              boxShadow: '0px 0px 20px 0px #516176',
              zIndex: '1',
              borderRadius: '0px',
              minHeight: '600px',
              maxHeight: '600px',
            }}
          >
            <Row>
              <Col
                md={3}
                style={{
                  borderRight: '1px solid #e4e6e9',
                  minHeight: '600px',
                  maxHeight: '600px',
                  overflow: 'auto',
                  boxShadow: 'rgb(169 172 177) 0px 0px 20px -7px',
                }}
                className='py-10 px-5 ps-8'
              >
                {/* Development */}
                <div className='mb-8 mt-7'>
                  <h4 className='my-2'>
                    <label className='text-dark fnt-500'> Development</label>
                  </h4>

                  <h4 className='my-2 text-dark'>
                    <label className='text-dark fnt-700'>{unitsData?.developmentName}</label>
                  </h4>
                </div>

                {/* Furnishing Type*/}
                <div className='mb-8 mt-3'>
                  <h4 className='my-2'>
                    <label className='text-dark fnt-500'> Furnishing Type</label>
                  </h4>

                  <h4 className='my-2 text-dark'>
                    <label className='text-dark fnt-700'>{furnishedValues || '-'}</label>
                  </h4>
                </div>

                {/* Unit Type*/}
                <div className='mb-8 mt-3'>
                  <h4 className='my-2'>
                    <label className='text-dark fnt-500'> Unit Type</label>
                  </h4>

                  <h4 className='my-2 text-dark'>
                    <label className='text-dark fnt-700'>{unitTypeValues || '-'}</label>
                  </h4>
                </div>

                {/* Bedrooms*/}
                <div className='mb-8 mt-3'>
                  <h4 className='my-2'>
                    <label className='text-dark fnt-500'> Bedrooms</label>
                  </h4>

                  <h4 className='my-2 text-dark'>
                    <label className='text-dark fnt-600'>{bedroomsValues || '-'}</label>
                  </h4>
                </div>

                {/* Total Units*/}
                <div className='mb-8 mt-3'>
                  <h4 className='my-2'>
                    <label className='text-dark fnt-500'> Total Units</label>
                  </h4>

                  <h4 className='my-2 '>
                    <label className='text-dark fnt-700'>{unitsData?.units?.length} Units</label>
                  </h4>
                </div>
              </Col>

              {/*  */}
              <Col md={9}>
                <div className='d-flex mb-1'>
                  <label
                    className='head-text fs-3 cursor-pointer ms-3 fnt-700 mt-3 ms-auto'
                    onClick={() => {
                      handleClose()
                    }}
                  >
                    X
                  </label>
                </div>

                {/*  */}
                <div
                  className='table-responsive px-5'
                  style={{maxHeight: '550px', overflow: 'auto'}}
                >
                  <table className='table align-middle gy-5' id='kt_ecommerce_sales_table'>
                    <thead className='fs-5'>
                      <tr>
                        <th className='text-end min-w-25px'></th>
                        <th className='text-start min-w-125px'>Unit</th>
                        <th className='text-start min-w-125px'>Floor</th>
                        <th className='text-start min-w-125px'>Furnishing Type</th>
                        <th className='text-start min-w-125px'>Unit Type</th>
                        <th className='text-start min-w-125px'>Bedrooms</th>
                      </tr>
                    </thead>
                    <tbody className='fw-semibold head-text'>
                      {devUnits?.length > 0 ? (
                        <>
                          {devUnits?.map((unit: any, index: any) => (
                            <tr key={index}>
                              <td className='text-start ps-0 test'>
                                <div className='form-check form-check-custom form-check-solid form-check-sm mb-2 py-0 ps-0 pe-5'>
                                  <input
                                    className='form-check-input cursor-pointer'
                                    type='checkbox'
                                    id='vacant0'
                                    name='none'
                                    checked={!!unit?.isSelected}
                                    onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                                  />
                                </div>
                              </td>
                              <td>{unit.unitNo || '-'}</td>
                              <td>{unit.floorId ? unit?.floor?.name : '-'}</td>
                              <td>
                                {unit.furnishingType == 0
                                  ? 'Unfurnished'
                                  : unit.furnishingType == 1
                                  ? 'Furnished'
                                  : '-'}
                              </td>
                              <td>{getUnitTypeName(unit?.unitType, unit)}</td>
                              <td>{unit.bedrooms != null ? unit.bedrooms : '-'} BR</td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <tr>
                          <td colSpan={6}>
                            <div
                              className='d-flex justify-content-center'
                              style={{minHeight: '400px', verticalAlign: 'middle'}}
                            >
                              <h3 className='top ps-0 d-block my-auto light-dark-theme-color'>
                                <i className='gry-dark-text i_grey'> No Records</i>
                              </h3>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
        </Row>
      </Modal>
    </div>
  )
}

export default SelectUnits
