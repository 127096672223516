
import styled from 'styled-components';
import {Bucket as bucketUrl} from '../../../apiCommon/helpers/API/ApiData'
import ReactDOMServer from 'react-dom/server'
import {Fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import { useEffect } from 'react';

// Styled components
export const PreviewContainer = styled.div`
  padding: 2rem;
  background: #ffffff;
  overflow: auto;
  max-height: 80vh;
  min-width: 80vw;
  max-width: 80vw;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const CsvTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  background: #ffffff;
  font-size: 14px;

  th, td {
    border: 1px solid #e2e8f0;
    padding: 12px;
    text-align: left;
  }

  th {
    background: #f8fafc;
    font-weight: 600;
    color: #1a202c;
  }

  tr:nth-child(even) {
    background: #f8fafc;
  }

  tr:hover {
    background: #edf2f7;
  }
`;

export const TxtContent = styled.pre`
  font-family: 'Monaco', 'Consolas', monospace;
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 4px;
  color: #1a202c;
  line-height: 1.5;
  font-size: 14px;
  margin: 0;
`;

export const ErrorContainer = styled.div`
  display: flex;
  background-color: #ffffff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  color: #ef4444;
  margin: auto;
`;

export const handleIframeLoad = (iframeElement: HTMLIFrameElement, timeoutDuration = 3000) => {
    return new Promise<void>((resolve, reject) => {
      iframeElement.onload = null;
      iframeElement.onerror = null;

      const timeoutId = setTimeout(() => {
        reject(new Error('Iframe loading timed out'));
      }, timeoutDuration);

      iframeElement.onload = () => {
        clearTimeout(timeoutId);
        resolve();
      };

      iframeElement.onerror = () => {
        clearTimeout(timeoutId);
        reject(new Error('Iframe failed to load'));
      };

      const currentSrc = iframeElement.src;
      iframeElement.src = '';
      iframeElement.src = currentSrc;
    });
  };

export const TextFilePreview = ({ content, fileExtension }: { content: string; fileExtension: string }) => {
    if (fileExtension === 'csv') {
      try {
        const rows = content.split('\n')
          .filter(row => row.trim())
          .map(row => row.split(','));
        
        const headers = rows[0];
        const dataRows = rows.slice(1);

        return (
          <PreviewContainer>
            <CsvTable>
              <thead>
                <tr>
                  {headers.map((header, i) => (
                    <th key={i}>{header.trim()}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataRows.map((row, i) => (
                  <tr key={i}>
                    {row.map((cell, j) => (
                      <td key={j}>{cell.trim()}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </CsvTable>
          </PreviewContainer>
        );
      } catch (error) {
        console.error('Error parsing CSV:', error);
        return (
          <ErrorContainer>
            <p style={{fontWeight: 'bold'}}>Error parsing CSV file</p>
          </ErrorContainer>
        );
      }
    }
    
    return (
      <PreviewContainer>
        <TxtContent>{content}</TxtContent>
      </PreviewContainer>
    );
  };

 export const handleImageClick = (attachments:Array<any>,groupName:any,itemName:string) => {
  console.log(attachments)
    attachments?.forEach((attachment, idx) => {
      const element = document.getElementById(`${itemName}${idx}`);
      const fileExtension = attachment?.URL.split('.').pop()?.toLowerCase();
      
      const isImage = ["jpg", "jpeg", "png", "gif", "svg", "pdf", "mp4", "webm"].includes(fileExtension || '');
      const isDocument = ["doc", "docx", "xls", "xlsx", "ppt", "pptx"].includes(fileExtension || '');
      const isPlainText = ["txt", "csv"].includes(fileExtension || '');
      
      element?.removeAttribute('data-state');

      if (element) {
        if (isImage) {
          element.setAttribute('data-src', `${bucketUrl}${attachment?.URL}`);
          element.setAttribute('data-fancybox', groupName);
        } else if (isDocument) {
          let viewerUrl = '';
          
          if (fileExtension === 'pptx' || fileExtension === 'ppt') {
            viewerUrl = `https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(`${bucketUrl}${attachment?.URL}`)}`;
          } else if (["doc", "docx", "xls", "xlsx"].includes(fileExtension || '')) {
            viewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(`${bucketUrl}${attachment?.URL}`)}`;
          }
          
          element.setAttribute('data-src', viewerUrl);
          element.setAttribute('data-type', 'iframe');
          element.setAttribute('data-fancybox', groupName);
        } else if (isPlainText) {
          const fileUrl = `${bucketUrl}${attachment?.URL}`;
          element.setAttribute('data-src', fileUrl);
          element.setAttribute('data-type', 'html');
          element.setAttribute('data-fancybox', groupName);
        }
      }
    });
  };

  interface UseFancyboxOptions {
    dependencies?: any[]; // Optional dependencies array for the effect
  }

  export const useFancybox = ({ 
    dependencies = []
  }: UseFancyboxOptions) => {
    useEffect(() => {
      let isAlreadyReveal = false;
      
      const processSlide = async (fancybox: any, slide: any) => {
        setTimeout(async () => {
          
          const slideContent = slide.contentEl;
          const fileUrl = slide.src;
          const fileExtension = fileUrl?.split('.')?.pop()?.toLowerCase();
  
          if (!slideContent) return;
  
          if (fileExtension === 'pptx' || fileExtension === 'ppt') {
            isAlreadyReveal = true;
            const iframe = slideContent.querySelector('iframe');
            
            try {
              fancybox.showLoading(slide);
              await handleIframeLoad(iframe, 30000);
              fancybox.hideLoading(slide);
              slide.state = 'done';
            } catch (error) {
              console.error('Error loading PPT:', error);
              fancybox.hideLoading(slide);
              
              const errorHtml = ReactDOMServer.renderToString(
                <ErrorContainer>
                  <p style={{fontWeight: 'bold', fontSize: '18px'}}>Preview not available</p>
                </ErrorContainer>
              );
              slideContent.innerHTML = errorHtml;
              slide.state = 'error';
            }
          } else if (fileExtension === 'txt' || fileExtension === 'csv') {
            try {
              fancybox.showLoading(slide);
              const response = await fetch(fileUrl);
              if (!response.ok) throw new Error('Failed to fetch file');
              
              const content = await response.text();
              const previewHtml = ReactDOMServer.renderToString(
                <TextFilePreview content={content} fileExtension={fileExtension} />
              );
              
              slideContent.innerHTML = previewHtml;
              fancybox.hideLoading(slide);
              slide.state = 'done';
            } catch (error) {
              console.error('Error loading text file:', error);
              fancybox.hideLoading(slide);
              const errorHtml = ReactDOMServer.renderToString(
                <ErrorContainer>
                  <p style={{fontWeight: 'bold', fontSize: '18px'}}>Preview not available</p>
                </ErrorContainer>
              );
              slideContent.innerHTML = errorHtml;
              slide.state = 'error';
            }
          }
        }, 0);
      };
  
      Fancybox.destroy();
  
      Fancybox.bind('[data-fancybox]', {
        Hash: false,
        on: {
          'init': (fancybox: any) => {
            fancybox?.Carousel?.slides?.forEach((slide: any) => {
              slide.state = undefined;
            });
          },
          'loading': async (fancybox: any, slide: any) => {
            setTimeout(async () => {
              if (!isAlreadyReveal && (!slide || slide.state !== 'done')) {
                await processSlide(fancybox, slide);
              }
            }, 0);
          },
          'reveal': async (fancybox: any, slide: any) => {
            if (!isAlreadyReveal && (!slide || slide.state !== 'done')) {
              await processSlide(fancybox, slide);
            }
          },
          'Carousel.change': async (fancybox: any, carousel: any) => {
            const currentSlide = carousel.slides[carousel.page];
            if (!currentSlide || currentSlide.state !== 'done') {
              await processSlide(fancybox, currentSlide);
            }
          },
          'close': () => {
            isAlreadyReveal = false;
            const slides = document.querySelectorAll('.fancybox__slide');
            slides.forEach((slide: any) => {
              if (slide) {
                slide.state = undefined;
              }
            });
          }
        }
      });
  
      return () => {
        Fancybox.destroy();
      };
    }, dependencies); // Use the provided dependencies array
  };

  

 

 
