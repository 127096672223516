
import React, {useEffect, useState, useRef} from 'react'
import {Bucket} from '../../../../../../apiCommon/helpers/API/ApiData'
import trashImg from '../../../../../../img/trash.png'
import AddAttachments from './AddAttachments'
import downloadIcon from '../../../../../../img/download.png'
import greenPen from '../../../../../../img/pen.png'
import addIcon from '../../../../../../img/add.png'
import AttachmentPreview from '../../../../General/AttachmentsPreview'
import {downloadFile, getFileIcon} from '../../../../../../Utilities/utils'


import {  handleImageClick, useFancybox } from "../../../../General/FilePreviewFunctions"

interface AttachmentModelI {
  showw?: any
  handleClose?: any
  expenseData?: any
  updateParent?: any
  isEdit: any
}

const AttachmentModel: React.FC<AttachmentModelI> = ({
  showw,
  handleClose,
  expenseData,
  updateParent,
  isEdit = false,
}) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [showAttachAdd, setShowAttachAdd] = React.useState<any>(false)

  let [expData, setExpData] = useState<any>(expenseData)

  const [mode, setMode] = useState<any>('add')
  const [selectedInd, setSelectedInd] = useState<any>(-1)
  const [selectedRecord, setSelectedRecord] = useState<any>({})

  useEffect(() => {
    setExpData(expenseData)
  }, [expenseData])

  const [attachmentsForPreview, setAttachmentsForPreview] = useState<any>([])

  useFancybox({
    dependencies: [] // Add any dependencies that should trigger a re-initialization
  });

  return (
    <>
      <div className={'card card-flush py-5 mt-2  px-5 mb-5 mx-2 min-hg-300'}>
        <div className='d-flex  align-items-center mb-5'>
          <button
            type='button'
            className='btn btn-sm fw-bold px-3 green-hollow-btn btn-fit-content '
            onClick={() => {
              setMode('add')
              setSelectedRecord({
                name: '',
                URL: '',
              })
              setTimeout(() => {
                setShowAttachAdd(true)
              }, 300)
            }}
          >
            <img src={addIcon} height={16} width={16} className='me-4' /> Add Cheque
          </button>
        </div>

        <div className='table-responsive mt-4' style={{maxHeight: '300px', overflow: 'auto'}}>
          <table className='table align-middle  fs-6 gy-5' id='kt_ecommerce_sales_table'>
            <tbody className='fw-semibold text-gray-600'>
              {expData.attachments?.map((inv: any, index: any) => {
                return (
                  <tr className=''>
                    <td className='text-start ps-0'>
                      <div className='d-flex align-items-center'>
                        <div className='bullet-item me-3 mb-0 ms-1'></div>
                        <p className='mb-0 white-dark-theme-color'>{inv?.name}</p>
                      </div>
                    </td>
                    <td className='text-start min-w-100px'>
                      <div className='d-flex align-items-center'>
                        <img
                          src={getFileIcon(inv?.URL)}
                          height={30}
                          width={30}
                          className='ms-3 cursor-pointer'
                          id={`clickedAttachments${index}`}
                          key={`clickedAttachments${index}`}
                          onClick={(e: any) => {
                            handleImageClick(expData.attachments,'group_attachments_others','clickedAttachments')
                          }}
                          data-src={`${Bucket}${inv?.URL}`}
                        />

                        <img
                          src={downloadIcon}
                          height={18}
                          width={18}
                          className='ms-5 cursor-pointer'
                          onClick={() => {
                            downloadFile(`${Bucket}${inv?.URL}`, inv?.name)
                          }}
                        />
                        {/* </a> */}
                        <img
                          src={greenPen}
                          height={18}
                          width={18}
                          className='ms-5 cursor-pointer'
                          onClick={() => {
                            // if (isEdit === false) return
                            setMode('edit')
                            setSelectedInd(index)
                            setSelectedRecord(inv)
                            setShowAttachAdd(true)
                          }}
                        />
                        <img
                          src={trashImg}
                          height={18}
                          width={18}
                          className='mx-5 cursor-pointer'
                          onClick={() => {
                            // if (isEdit === false) return
                            let values = {...expData}
                            values.attachments.splice(index, 1)
                            updateParent(values.attachments)
                            setExpData(values)
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        {/* <h1>Attachment Previews</h1> */}
        {attachmentsForPreview && <AttachmentPreview attachments={attachmentsForPreview} />}
      </div>

      {showAttachAdd && mode && (
        <AddAttachments
          attachments={expData.attachments}
          show={showAttachAdd}
          handleClose={() => setShowAttachAdd(false)}
          mode={mode}
          isEdit={isEdit}
          updateData={(data: any) => {
            let values = {...expData}
            console.log(values)
            if (mode == 'add') {
              values.attachments.push(data)
            } else {
              values.attachments[selectedInd] = data
            }
            setExpData(values)

            updateParent(values.attachments)
            setShowAttachAdd(false)
          }}
          selectedItem={selectedRecord}
        />
      )}
    </>
  )
}

export default AttachmentModel
